import React, { useEffect, useState } from "react";
import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Toolbar, styled, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { pages, pagesLang } from "../constants"
import { useNavigate } from "react-router-dom";
import logo from "../assests/logoOG.png"
import "./index.css"
import BasicMenu from "./DropDown";
import img1 from "../assests/web-development-svgrepo-com.png"
import img2 from "../assests/mobile-app-developing-svgrepo-com.png"
import img3 from "../assests/nlp-natural-language-processing.png"
import img4 from "../assests/Mask group.png"
import img5 from "../assests/Custom Software Development.png"
import img6 from "../assests/web.png"
import img7 from "../assests/Digital Marketing.png"
import img8 from "../assests/chatbot.png"
import img9 from "../assests/Cloud Computing.png"
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import ComputerIcon from '@mui/icons-material/Computer';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import tp from "../assests/program.png"
import tp1 from "../assests/trainer.png"
import tp2 from "../assests/devlopmentProgram.jpg"
import tp3 from "../assests/industryIcon.jpg"
import tp4 from "../assests/diverstyIcon.jpg"
import tp5 from "../assests/projectM.jpg"
import ms from "../assests/socialMedia.png"
import ms1 from "../assests/SEO.png"
import ms2 from "../assests/Outreach.jpg"
import ms3 from "../assests/lead.png"
import ms4 from "../assests/networj.png"
import ms5 from "../assests/lead.png"
import ms6 from "../assests/CRM.jpg"
import ms7 from "../assests/salesf.png"
import Digitals from "../assests/ggggg.png"
import Comp from "../assests/comp.png"
import Inst from "../assests/inst.png"
import ContactIcon from "../assests/conttt.png"
import infoIcon from "../assests/information-button.png"
import { useApp } from "../context/AppContext";
const DrawerComp = () => {
  const { language } = useApp()
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openHomeMenu, setOpenHomeMenu] = React.useState(false);
  const [openTech, setOpenTech] = React.useState(false);
  const [openDigital, setOpenDigital] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const toggleHomeMenu = () => {
    setOpenHomeMenu(!openHomeMenu);
  };

  const toggleTech = () => {
    setOpenTech(!openTech);
  };
  const toggleDigital = () => {
    setOpenDigital(!openDigital);
  };



  const scrollToWebDevelopment = (id) => {
    navigate('/')
    setTimeout(() => {
      setOpenDrawer(false);
      setOpenHomeMenu(false);
      setOpenTech(false);
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }, 300)

  }
  return (
    <>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List style={{ minWidth: "313.8px", overflowY: "auto" }}>
          <div style={{ marginLeft: "20px", width: "100px", height: "40px", paddingTop: "10px", cursor: "pointer" }} onClick={() => navigate("/")}>
            <img src={logo} alt="logo img" />
          </div>
          <hr style={{ color: "red" }} />
          <ListItemButton onClick={() => { navigate('/AboutUs'); toggleDrawer() }} key="about-contact">
            <ListItemIcon>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <img src={infoIcon} width={25} />
                </ListItemIcon>
                <ListItemText className="mainBtn"> {language == 'en' ? `About Us` : `Qui sommes-nous`}</ListItemText>
              </div>
            </ListItemIcon>
          </ListItemButton>
          <ListItemButton onClick={() => { navigate('/ContactUs'); toggleDrawer() }} key="contact-contact">
            <ListItemIcon>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <img src={ContactIcon} width={25} />
                </ListItemIcon>
                <ListItemText className="mainBtn">{language == 'en' ? "Contact Us" : "Contactez-nous"}</ListItemText>
              </div>
            </ListItemIcon>
          </ListItemButton>

          <ListItemButton onClick={toggleHomeMenu} key="home">
            <div style={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <img src={Inst} width={25} />
              </ListItemIcon>
              <ListItemText className="mainBtn">{language == 'en' ? `Training` : `Formation`}</ListItemText>
            </div>
          </ListItemButton>
          {openHomeMenu && (
            <List>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }} key="home-subitem1">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={tp} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Curriculum Development` : `Élaboration de programmes d’études`}<br /> {language == 'en' ? `Programs` : `Programmes`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={tp1} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Train the Trainer Programs` : `Programmes de formation des formateurs`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={tp2} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Management Development` : `Développement de la gestion`}<br />  {language == 'en' ? `Programs` : `Programmes`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={tp3} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Industry Specific` : `Spécifique à l’industrie`}<br />{language == "en" ? `Trainings` : `Formations`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={tp4} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Equity, Diversity, and` : `l’équité, la diversité et`} <br />{language == 'en' ? `Inclusion Groups Training` : `Formation des groupes d’inclusion`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={tp5} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Project Management Trainings` : `Formations en gestion de projet`}</ListItemText>
                </div>
              </ListItemButton>
            </List>
          )}
          <ListItemButton onClick={toggleTech} key="Tech">
            <div style={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <img src={Comp} width={25} />
              </ListItemIcon>
              <ListItemText className="mainBtn">Tech</ListItemText>
            </div>
          </ListItemButton>
          {openTech && (
            <List>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }} key="home-subitem1">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={img1} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Web Development` : `Développement Web`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={img2} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Mobile Development` : `Développement mobile`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={img3} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">NLP</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={img4} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `UI/UX Designing` : `Conception UI/UX`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={img5} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Custom Software Development` : `Développement de logiciels sur mesure`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={img6} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `SaaS Development` : `Développement SaaS`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={img7} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Digital Marketing` : `Marketing numérique`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={img8} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Intelligent Chat Systems` : `Systèmes de chat intelligents`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={img9} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Cloud Engineering & Devops` : `Ingénierie du cloud et Devops`}</ListItemText>
                </div>
              </ListItemButton>

            </List>

          )}
          <ListItemButton onClick={toggleDigital} key="DigitalMarkeeting">
            <div style={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <img src={Digitals} width={25} />
              </ListItemIcon>
              <ListItemText className="mainBtn">{language == 'en' ? `Digital` : `Numérique`}</ListItemText>
            </div>
          </ListItemButton>
          {openDigital && (
            <List>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }} key="home-subitem1">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={ms} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Social Media Marketing` : `Marketing des médias sociaux`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={ms1} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Search Engine Optimization` : `Optimisation pour les moteurs de recherche`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={ms2} width={25} />

                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Outreach Programs` : `Programmes de sensibilisation`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={ms3} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Lead Generation` : `Génération de leads`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={ms4} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Networking & Collaboration` : `Réseautage et collaboration`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={ms6} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Media Strategy & Management` : `Stratégie et gestion des médias`}</ListItemText>
                </div>
              </ListItemButton>
              <ListItemButton onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }} key="home-subitem2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <img src={ms6} width={25} />
                  </ListItemIcon>
                  <ListItemText className="mainBtn">{language == 'en' ? `Consulting Services` : `Services de conseil`}</ListItemText>
                </div>
              </ListItemButton>
            </List>
          )}
        </List>
      </Drawer >
      <IconButton
        sx={{ color: "black", marginLeft: "auto" }}
        onClick={toggleDrawer}
      >
        <MenuIcon color="white" />
      </IconButton>
    </>
  );
};

const SimpleTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
  })
);

export default function Navbar() {
  const { language } = useApp()

  const [value, setValue] = useState();
  const theme = useTheme();
  const isDestop = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate()
  const lang = language === "en" ? pages : pagesLang

  useEffect(() => {
    const url = window.location.href.split("/")[3]
    if (url === "AboutUs") {
      setValue(0)
    } else if (url === "ContactUs") {
      setValue(1)
    } else {
      setValue("")
    }

  }, [])

  const handleChange = (value) => {
    setValue(value)
    const url = value === 0 ? "/AboutUs" : value === 1 ? "/ContactUs" : "/"
    navigate(url)
  }
  return (
    <>
      <div sx={{ background: "#0062F2", minWidth: "100%" }}>
        <Toolbar className="mainNav" style={{ justifyContent: "center" }}>
          <BasicMenu />
          <div style={{ width: "100px", height: "50px", paddingTop: "20px", cursor: "pointer" }} onClick={() => navigate("/")}>
            <img src={logo} alt="lgo img" />
          </div>
          {isDestop ? (
            <>
              <DrawerComp navigate={navigate} />
            </>
          ) : (
            <>
              <Tabs
                sx={{ marginLeft: "50px" }}
                indicatorColor="primary"
                textColor="inherit"
                value={value}
                onChange={(e, value) => handleChange(value)}
              >
                {lang.map((pg, index) => (
                  <SimpleTab label={pg} />
                ))
                }
              </Tabs>
            </>
          )}
        </Toolbar>
      </div>
    </>
  );
}
