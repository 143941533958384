import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router'
import { SocialIcon } from 'react-social-icons';
import logo from "./assests/removeBg.png"
import { Link } from 'react-router-dom';
import { useApp } from './context/AppContext';
export default function Footer() {
  const { language, setLanguage } = useApp()
  const theme = useTheme();
  const isDestop = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleLanChange = (lang) => {
    localStorage.setItem('lang', lang)
    setLanguage(lang)

  }
  return (
    <div
      style={{ width: "100%", backgroundColor: "#0062F2", height: "401px" }}
    >
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: isDestop ? "40px" : "100px",
        }}
      >
        <Grid
          item
          xs={12}
          md={3}
          // lg={2}
          style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <Link to='/' style={{ height: "37px", width: "134px" }}>
            <img src={logo} alt="logo img" />
          </Link>
          <p style={{ color: "white", marginLeft: "15px" }}>
            Awarecan Inc Unit 503-407 Dundas Street West Oakville ON
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
            <p
              style={{
                marginRight: "80px",
                fontFamily: "Open Sans",
                fontSize: "16px",
                color: "white",
                fontWeight: 400,
                cursor: "pointer"
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/AboutUs')
              }}
            >
              {language == 'en' ? `About Us` : `Qui sommes-nous`}
            </p>
            <p
              style={{
                marginRight: "80px",
                fontFamily: "Open Sans",
                fontSize: "16px",
                color: "white",
                fontWeight: 400,
                cursor: "pointer"
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/ContactUs')
              }}
            >
              {language == 'en' ? "Contact Us" : "Contactez-nous"}
            </p>
            <div class="select">
              <select id="standard-select" value={language} onChange={(e) => handleLanChange(e.target.value)}>
                <option value={'en'} className='opt'>English</option>
                <option value={'fr'} className='opt'>French</option>
              </select>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div style={{ display: "flex", cursor: "pointer" }}>
            <SocialIcon
              url="https://twitter.com"
              bgColor="black"
              style={{ height: "28px", width: "28px", marginRight: "10px" }}
            />
            <SocialIcon
              url="https://facebook.com"
              bgColor="#e29a1b"
              style={{ height: "28px", width: "28px", marginRight: "10px" }}
            />
            <SocialIcon
              url="https://instagram.com"
              bgColor="black"
              style={{ height: "28px", width: "28px", marginRight: "10px" }}
            />
            {/* <SocialIcon
              url="https://github.com"
              bgColor="black"
              style={{ height: "28px", width: "28px" }}
            /> */}
          </div>
        </Grid>
      </Grid>
      <hr width="85%" style={{ marginTop: isDestop ? "60px" : "100px" }} />
      <p
        style={{
          marginTop: "40px",
          fontFamily: "Open Sans",
          fontWeight: 400,
          fontSize: "14px",
          color: "white",
          textAlign: "center",
        }}
      >
        © Copyright 2023, All Rights Reserved by AwareCan
      </p>
    </div >
  )
}
