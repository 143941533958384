import React, { useRef } from "react";
import cover1 from "../assests/cover1.png";
import bg from "../assests/BG.png";
import { Button, Card, CardContent, Grid, TextField, styled, useMediaQuery, useTheme } from "@mui/material";
import client1 from "../assests/client1.png";
import client2 from "../assests/client2.png";
import client3 from "../assests/client3.png";
import client4 from "../assests/fsdppp.png";
import client5 from "../assests/bbbbbb.png";
import { objects, TrainingPrograms, Strategies, Services, objectsLang, TrainingProgramsLang, StrategiesLang, ServicesLang } from "../constants";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import Navbar from "../Navbar"
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import { useApp } from "../context/AppContext";

const ContactButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: "24px",
  backgroundColor: "white",
  borderColor: "white",
  fontFamily: "Open Sans",
  color: "black",
});
const ContactButton2 = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: "24px",
  backgroundColor: "white",
  borderColor: "white",
  fontFamily: "Open Sans",
  backgroundColor: "#0062F2",
  color: "white",
  '&:hover': {
    backgroundColor: "#0062F2",
    color: "white",
  }
});

const MessageButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: "24px",
  backgroundColor: "#0062F2",
  borderColor: "white",
  fontFamily: "Open Sans",
  color: "white",
  '&:hover': {
    backgroundColor: "#0062F2",
    color: "white",
  }
});

const ExploreButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: "24px",
  backgroundColor: "transparent",
  borderColor: "white",
  fontFamily: "Open Sans",
  color: "white",
});

const HoverGrid = styled(Grid)({
  '&:hover': {
    transform: 'scale(1.05)',
  },
});
export default function Homepage() {

  const theme = useTheme();
  const isDestop = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const ref = useRef(null);
  const { language } = useApp()

  const handleClickref = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const lang = language === "en" ? objects : objectsLang
  const Train = language === "en" ? TrainingPrograms : TrainingProgramsLang
  const Strag = language === "en" ? Strategies : StrategiesLang
  const ServiceLan = language === "en" ? Services : ServicesLang



  const ids = ["WebDevelopment", "MobileDevelopment", "NLP", "UIUXDesigning", "CustomSoftwareDevelopment", "SaaSDevelopment", "DigitalMarketing", "IntelligentChatSystems", "CloudEngineeringDevops"]
  const ids1 = ["CurriculumDevelopmentPrograms", "TraintheTrainerPrograms", "ManagementDevelopmentPrograms", "IndustrySpecificTrainings", "EquityDiversityInclusionGroupsTraining", "ProjectManagementTrainings"]
  const ids2 = ["DigitalMarketing", "SearchEngineOptimization", "OutreachPrograms", "LeadGeneration", "NetworkingCollaboration", "BusinessProcessAutomation", "CRMImplementations", "SalesforceImplementation"]
  const ids3 = ["CRMImplementation", "BusinessProcessOutsourcing", "DigitalTransformation", "MarketResearch", "MediaStrategyManagement"]
  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          id="first div"
          style={{
            backgroundImage: `url(${cover1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            width: "100%",
            minHeight: 'calc(100vh - 70px)',
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              width: "700px",
            }}
          >
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontWeight: "400",
                fontSize: "62px",
                color: "white",
              }}
            >
              {language == 'en' ? 'Empowering Businesses with Digital Precision' : 'Donner aux entreprises les moyens de bénéficier d’une précision numérique'}
            </h1>
            <h5
              style={{
                fontFamily: "Open Sans",
                fontWeight: "400",
                fontSize: "18px",
                color: "white",
              }}
            >
              {
                language == 'en' ? "Discover AwareCan, the driving force behind businesses' digital transformations. Our commitment to digital precision equips businesses with the insights and strategies needed to conquer the competitive marketing landscape." : 'Découvrez AwareCan, le moteur des transformations numériques des entreprises. Notre engagement envers la précision numérique donne aux entreprises les informations et les stratégies nécessaires pour conquérir le paysage marketing concurrentiel.'
              }

            </h5>
            <div>
              <ContactButton onClick={() => navigate('/ContactUs')}>{language == 'en' ? "Contact Us" : "Contactez-nous"}</ContactButton>
              <ExploreButton style={{ marginLeft: "20px" }} onClick={handleClickref}>
                {language == "en" ? 'Explore Now' : 'Explorez maintenant'}
              </ExploreButton>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              width: "80%",

            }}
          >
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontWeight: "400",
                fontSize: "40px",
              }}
            >
              {language == 'en' ? "Awaken Your Brand's Potential with Our Marketing Wizardry" : 'Réveillez le potentiel de votre marque avec notre magie marketing'}
            </h1>
            <h5
              style={{
                fontFamily: "Open Sans",
                fontWeight: "400",
                fontSize: "18px",
                paddingLeft: "20px",
                paddingRight: "20px"
              }}
            >
              {language == 'en' ? `At AwareCan, we specialize in helping small and medium businesses
              succeed in today's digital landscape. We achieve this by creating
              effective marketing strategies that harness the power of data and
              technology. We focus on building strong community engagement and
              managing social media to drive growth for our clients. We're here
              to make your business thrive online.` : `Chez AwareCan, nous nous spécialisons dans l'aide aux petites et moyennes entreprises
              réussir dans le paysage numérique d'aujourd'hui. Nous y parvenons en créant
              des stratégies marketing efficaces qui exploitent la puissance des données et
              technologie. Nous nous concentrons sur la création d’un engagement communautaire fort et
              gérer les médias sociaux pour stimuler la croissance de nos clients. Nous sommes là
              pour faire prospérer votre entreprise en ligne.`
              }
            </h5>
            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
              <img
                src={client1}
                alt="client 1"
                style={{ marginRight: "50px" }}
              />
              <img
                src={client2}
                alt="client 2"
                style={{ marginRight: "50px" }}
              />
              <img src={client3} alt="client 3" style={{ marginRight: "50px" }} />
              <img src={client4} alt="client 3" style={{ marginRight: "50px" }} />
              <img src={client5} alt="client 3" />
            </div>
          </div>
        </div>

        <div
          id="third div"
          style={{
            // backgroundColor: "#0062F2",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            marginTop: "70px",
          }}
        >
          <div
            ref={ref}
            style={{
              textAlign: "center",
              width: "80%",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontWeight: "400",
                fontSize: "40px",
                color: "black",
                color: "black",
              }}
            >
              {language == 'en' ? `Ready to Unlock Your Marketing Potential? Take Our Marketing
              Assessment Now!` : `Prêt à libérer votre potentiel marketing ? Prenez notre marketing
              Évaluation maintenant !`}
            </h1>
            <h5
              style={{
                fontFamily: "Open Sans",
                fontWeight: "400",
                fontSize: "18px",
                color: "black",
              }}
            >
              {language == 'en' ? `Discover how AwareCan's tailored solutions can supercharge your
              business. Take our Marketing Assessment now to get started on your
              path to success` : `Découvrez comment les solutions sur mesure d'AwareCan peuvent dynamiser votre
              entreprise. Passez notre évaluation marketing dès maintenant pour commencer votre
              chemin vers le succès`}
            </h5>
            <div>
              <ContactButton2 onClick={() => navigate('/ContactUs')}>{language == 'en' ? "Contact Us" : "Contactez-nous"}</ContactButton2>
            </div>
          </div>
        </div>

        <div
          style={{ textAlign: "center", overflowX: "hidden" }}
          id='webDevelopment'
        >
          <h1
            style={{
              fontFamily: "DM Serif Display",
              fontSize: "40px",
              color: "white",
              marginBottom: "60px",
              marginTop: "60px",
              textAlign: "center",
              backgroundColor: "#0062F2",
              padding: '55px'
            }}
          >
            {language == 'en' ? `Maximizing Potential through Tech Solutions` : `Maximiser le potentiel grâce à des solutions technologiques`}
          </h1>

          <Grid container spacing={5} justifyContent={"center"} style={{ width: "100%", padding: "0 60px" }}>
            {lang?.map((entry, i) => (
              <HoverGrid item>
                <div style={{ textAlign: "left", width: "270px" }}>
                  <img
                    src={entry.icon}
                    style={{ height: "40px", width: "40px" }}
                  />

                  <p
                    style={{
                      fontFamily: "DM Serif Display",
                      fontSize: "26px",
                      fontWeight: 400,
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {entry.heading}
                  </p>
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#7a7a7a",
                      marginTop: "0px",
                    }}
                  >
                    {entry.subheading}
                  </p>
                </div>
              </HoverGrid>
            ))}
            <Grid item>
              <div style={{ width: "890px" }}>
              </div>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            width: "100%",
            textAlign: "center",
            overflowX: "hidden",
            // marginTop: "60px",
            paddingBottom: "50px",
          }}
        >
          <div
            id="third div"
            style={{
              backgroundColor: "#0062F2",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              marginTop: "70px",
            }}
          >
            <div
              id="CurriculumDevelopmentPrograms"
              ref={ref}
              style={{
                textAlign: "center",
                width: "80%",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <h1
                style={{
                  fontFamily: "DM Serif Display",
                  fontWeight: "400",
                  fontSize: "40px",
                  color: "white",
                }}
              >
                {language == 'en' ? `AwareCan's Comprehensive Training Programs` : `Programmes de formation complets d’AwareCan`}
              </h1>
            </div>
          </div>
          <div
            style={{ textAlign: "center", overflow: "hidden", padding: "0 60px" }}

          >
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontSize: "40px",
                color: "#090909",
                marginBottom: "60px",
                marginTop: "60px",
                textAlign: "center"
              }}
            >
              {language == 'en' ? `AwareCan offers diverse adaptable training programs cater to your unique needs` : <>AwareCan propose divers programmes de formation adaptables qui <br /> répondent à  vos besoins uniques</>}
            </h1>

            <Grid container spacing={5} justifyContent={"center"} style={{ width: "100%" }}>
              {Train?.map((entry, i) => (
                <HoverGrid item>
                  <div style={{ textAlign: "left", width: "270px" }}>
                    <img
                      src={entry.img}
                      style={{ height: "40px", width: "40px" }}
                    />

                    <p
                      style={{
                        fontFamily: "DM Serif Display",
                        fontSize: "26px",
                        fontWeight: 400,
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {entry.heading}
                    </p>
                    <p
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#7a7a7a",
                        marginTop: "0px",
                      }}
                    >
                      {entry.subheading}
                    </p>
                  </div>
                </HoverGrid>
              ))}
              <Grid item>
                {/* <div style={{ width: "890px" }}>
                </div> */}
              </Grid>
            </Grid>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            textAlign: "center",
            overflowX: "hidden",
            // marginTop: "60px",
            paddingBottom: "50px",
          }}
        >
          <div
            id="third div"
            style={{
              backgroundColor: "#0062F2",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              marginTop: "70px",
            }}
          >
            <div
              id="DigitalMarketing"
              ref={ref}
              style={{
                textAlign: "center",
                width: "80%",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <h1
                style={{
                  fontFamily: "DM Serif Display",
                  fontWeight: "400",
                  fontSize: "40px",
                  color: "white",
                }}
              >
                {language == 'en' ? `Leading the Way in Digital Marketing Strategies` : `Ouvrir la voie aux stratégies de marketing numérique`}
              </h1>
            </div>
          </div>
          <div
            style={{ textAlign: "center", overflow: "hidden", padding: "0 60px" }}
            id="webDevelopement"
          >
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontSize: "40px",
                color: "#090909",
                marginBottom: "60px",
                marginTop: "60px",
                textAlign: "center"
              }}
            >
              {language == 'en' ? `Digital strategy leaders excelling in SEO, content, social media, and PPC` : <>Les leaders de la stratégie numérique excellent dans les domaines du  <br /> référencement, du contenu, des médias sociaux et du PPC</>}
            </h1>

            <Grid container spacing={5} justifyContent={"center"} style={{ width: "100%" }}>
              {Strag?.map((entry, i) => (
                <HoverGrid item id={ids2[i]}>
                  <div style={{ textAlign: "left", width: "270px" }}>
                    <img
                      src={entry.img}
                      style={{ height: "40px", width: "40px" }}
                    />

                    <p
                      style={{
                        fontFamily: "DM Serif Display",
                        fontSize: "26px",
                        fontWeight: 400,
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {entry.heading}
                    </p>
                    <p
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#7a7a7a",
                        marginTop: "0px",
                      }}
                    >
                      {entry.subheading}
                    </p>
                  </div>
                </HoverGrid>
              ))}
              <Grid item>
                {/* <div style={{ width: "890px" }}>
                </div> */}
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            overflowX: "hidden",
            // marginTop: "60px",
            paddingBottom: "50px",
          }}
        >
          <div
            id="third div"
            style={{
              backgroundColor: "#0062F2",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              marginTop: "70px",
            }}
          >
            <div
              ref={ref}
              style={{
                textAlign: "center",
                width: "80%",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <h1
                style={{
                  fontFamily: "DM Serif Display",
                  fontWeight: "400",
                  fontSize: "40px",
                  color: "white",
                }}
              >
                {language == 'en' ? `Advising Excellence: AwareCan's Consulting Services` : `Conseiller l’excellence : les services-conseils d’AwareCan`}
              </h1>
            </div>
          </div>
          <div
            style={{ textAlign: "center", overflow: "hidden", padding: "0 60px" }}
            id="webDevelopement"
          >
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontSize: "40px",
                color: "#090909",
                marginBottom: "60px",
                marginTop: "60px",
                textAlign: "center"
              }}
            >
              {language == 'en' ? `Guiding Success Through Expertise Strategic Advisory Services Excellence` : `Guider le succès grâce à l’expertise Excellence des services-conseils stratégiques`}
            </h1>

            <Grid container spacing={5} justifyContent={"center"} style={{ width: "100%" }}>
              {ServiceLan?.map((entry, i) => (
                <HoverGrid item id={ids3[i]}>
                  <div style={{ textAlign: "left", width: "270px" }}>
                    <img
                      src={entry.img}
                      style={{ height: "40px", width: "40px" }}
                    />

                    <p
                      style={{
                        fontFamily: "DM Serif Display",
                        fontSize: "26px",
                        fontWeight: 400,
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {entry.heading}
                    </p>
                    <p
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#7a7a7a",
                        marginTop: "0px",
                      }}
                    >
                      {entry.subheading}
                    </p>
                  </div>
                </HoverGrid>
              ))}
              <Grid item>
                {/* <div style={{ width: "890px" }}>
                </div> */}
              </Grid>
            </Grid>
          </div>
        </div>


        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "60px",
            marginBottom: "60px",
          }}
        >
          {!isDestop && (
            <Card
              sx={{
                height: "625px",
                width: "433px",
                backgroundImage: `url(${bg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                borderRadius: "10px 0px 0px 10px",
              }}
            >
              <CardContent
                style={{ color: "white", borderRadius: "0px 10px 10px 0px" }}
              >
                <h1
                  style={{
                    fontFamily: "DM Serif Display",
                    fontWeight: 400,
                    fontSize: "24px",
                    marginTop: "30px",
                    marginBottom: "0px",
                  }}
                >
                  {language == 'en' ? `Contact Information` : ` Coordonnées`}
                </h1>
                <h5
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    fontSize: "14px",
                    marginTop: "15px",
                    maxWidth: "250px",
                  }}
                >
                  {language == 'en' ? `Fill up form and our team will get back to you within 12
                  hours.`: `Remplissez le formulaire et notre équipe vous répondra dans les 12H.`}
                </h5>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
                >
                  <CallIcon style={{ color: "#e29a1b" }} />
                  <h5
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      fontSize: "15px",
                      paddingLeft: "20px",
                    }}
                  >
                    +1-905-462-2972
                  </h5>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AlternateEmailIcon style={{ color: "#e29a1b" }} />
                  <h5
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      fontSize: "15px",
                      paddingLeft: "20px",
                    }}
                  >
                    aman@awarecan.ca
                  </h5>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LocationOnIcon style={{ color: "#e29a1b" }} />
                  <h5
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      fontSize: "15px",
                      paddingLeft: "20px",
                    }}
                  >
                    Awarecan Inc Unit 503-407 Dundas Street West, Oakville, ON
                    {/* {language == 'en' ? `Location` : `Emplacement`} */}
                  </h5>
                </div>
                <div style={{ display: "flex", marginTop: "150px" }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "39px",
                      width: "39px",
                      borderRadius: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "30px",
                      cursor: "pointer"
                    }}
                  >
                    <FacebookRoundedIcon
                      style={{
                        color: "#0062F2",
                        height: "45px",
                        width: "45px",

                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "39px",
                      width: "39px",
                      borderRadius: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "30px",
                      cursor: "pointer"

                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#0062F2",
                        height: "37px",
                        width: "37px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "39px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "27px",
                          width: "27px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "39px",
                          cursor: "pointer"

                        }}
                      >
                        <LinkedInIcon
                          style={{
                            color: "#0062F2",
                            height: "37px",
                            width: "37px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "39px",
                      width: "39px",
                      borderRadius: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid white",
                      cursor: "pointer"
                    }}
                  >
                    <TwitterIcon
                      style={{ color: "white", height: "32px", width: "32px" }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
          <Card sx={{ height: "625px", width: "847px" }}>
            <form action="https://formsubmit.co/aman@awarecan.ca" method="POST">
              <div style={{ padding: "40px 40px 0px 40px", width: "100%" }}>
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  {language == 'en' ? `Full name` : `Nom complet`}
                </h1>
                <TextField
                  placeholder="John"
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                  name="name"
                  required
                />
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  {language == 'en' ? `Email` : `Courriel`}
                </h1>
                <TextField
                  placeholder="info@company.com"
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                  name="email"
                  required
                />
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  {language == 'en' ? `Subject` : `Objet`}{" "}
                </h1>
                <TextField
                  placeholder="placeholder"
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                  name="subject"
                  required
                />
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  {language == 'en' ? `Message` : `Message`}
                </h1>
                <TextField
                  placeholder="Write your short message"
                  multiline
                  rows={3}
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                  name="message"
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "85%",
                  padding: "20px 40px 40px 40px",
                }}
              >
                <MessageButton type="submit">{language == 'en' ? `Send Message` : `Envoyer un message`}</MessageButton>
              </div>
            </form>
          </Card>
        </div>
        <Footer />
      </div>
    </div>
  );
}
