import React, { useEffect, useState } from "react";
import cover1 from "../assests/cover1.png";
import bg from "../assests/BG.png";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Trainers } from "../constants";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { SocialIcon } from "react-social-icons";
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import Navbar from "../Navbar"
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Footer from "../Footer";

const MessageButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: "24px",
  backgroundColor: "#006994",
  borderColor: "white",
  fontFamily: "Open Sans",
  color: "white",
});

function SimpleDialog({ open, onClose, name, txt }) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        About {name}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          {txt}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default function Homepage() {
  const theme = useTheme();
  const isDestop = useMediaQuery(theme.breakpoints.down("md"));
  const [TrainersState, setTrainersState] = useState(Trainers)

  const handleClose = (index) => {
    const updatedTrainers = [...Trainers];
    updatedTrainers[index].open = false;
    setTrainersState(updatedTrainers);
  };
  const handleClickOpen = (index) => {
    const updatedTrainers = [...Trainers];
    updatedTrainers[index].open = true;
    setTrainersState(updatedTrainers);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Navbar />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          id="first div"
          style={{
            backgroundImage: `url(${cover1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            width: "100%",
            height: "450px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              textAlign: "left",
              width: "700px",
              marginLeft: "80px"
            }}
          >
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontWeight: "400",
                fontSize: "62px",
                color: "white",
              }}
            >
              Training
            </h1>
          </div>
        </div>

        <div style={{ margin: "20px 50px 40px 50px" }}>
          <h1 style={{ fontFamily: "DM Serif Display", fontSize: "40px", lineHeight: "48px" }}>Introduction</h1>
          <span style={{ fontFamily: "Montserrat", fontWeight: 400, fontSize: "18px", lineHeight: "27px", color: "#667085" }}>
            <p>
              At AwareCan, we are dedicated to transforming small and medium-sized businesses by redefining their marketing strategies. Our approach is multifaceted, focusing on creating a marketing ecosystem that is not only effective but also scalable. What sets us apart is our ability to combine strong financial and business acumen with expert data analysis and a deep understanding of current technologies.
              <br /><br />
              Our team comprises professionals who are not only marketing experts but also possess a keen understanding of the financial landscape. We realize that marketing strategies cannot be isolated from a business’s financial goals. Therefore, we craft marketing plans that are not just creative but also financially sound, aligning with the broader objectives of our clients' businesses.
              <br /><br />
              Data lies at the heart of our strategies. We believe in the power of data-driven decision-making. Our experts meticulously analyze market trends, customer behavior, and the performance of various marketing channels. By diving deep into data, we unearth invaluable insights that drive our strategies. This analytical approach ensures that our marketing campaigns are not just based on intuition but on concrete, data-backed evidence, leading to more impactful and effective results.
              <br /><br />
              Moreover, we pride ourselves on our profound understanding of current technologies. In today's fast-paced digital landscape, staying ahead of technological advancements is paramount. AwareCan stays abreast of the latest tools, platforms, and digital marketing techniques. By integrating cutting-edge technology into our strategies, we help our clients leverage the full potential of the digital realm.
              <br /><br />
              Our business philosophy revolves around active community engagement, adept social media management, and digital optimization strategies tailored to fuel the growth of our clients. We don't just offer marketing services; we provide a comprehensive solution that empowers businesses to thrive in the competitive market. Through measurement, testing, and continuous iteration, we ensure that our clients not only reach their target audience but also create a lasting impact, fostering long-term success for their ventures.
            </p>
          </span>
        </div>

        <div style={{ margin: "20px 50px 40px 50px" }}>
          <h1 style={{ fontFamily: "DM Serif Display", fontSize: "40px", lineHeight: "48px" }}>Trainers</h1>
          <Grid container style={{ marginBottom: "0px" }}>
            {TrainersState.map((trainer, index) => (
              <Grid item key={index} style={{ textAlign: "left", width: "296px", marginRight: "30px", marginBottom: "70px" }}>
                <div style={{ height: "296px", width: "296px", borderRadius: "25px", backgroundColor: "lightgrey", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src={trainer.img} alt="at img" height="296px" width="296px" />
                </div>
                <p style={{ fontFamily: "DM Serif Display", fontSize: "20px", lineHeight: "30px" }}>{trainer.name}</p>
                <p style={{ fontFamily: "Open Sans", fontSize: "18px", lineHeight: "28px", color: "#E29A1B" }}>{trainer.position}</p>
                <p style={{ fontFamily: "Open Sans", fontSize: "16px", lineHeight: "24px", color: "#7a7a7a", cursor: "pointer" }} onClick={() => handleClickOpen(index)}>{trainer.desc.split(/\s+/).slice(0, 10).join(" ")}...</p>
                <SimpleDialog open={trainer.open} onClose={() => handleClose(index)} name={trainer.name} txt={trainer.desc} />
                <div style={{ display: "flex" }}>
                  <TwitterIcon style={{ height: "24px", width: "24px", marginRight: "10px", color: "#98a2b3" }} />
                  <LinkedInIcon style={{ height: "24px", width: "24px", marginRight: "10px", color: "#98a2b3" }} />
                  <SportsBasketballIcon style={{ height: "24px", width: "24px", marginRight: "10px", color: "#98a2b3" }} />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "60px",
          }}
        >
          {!isDestop && (
            <Card
              sx={{
                height: "600px",
                width: "433px",
                backgroundImage: `url(${bg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                borderRadius: "10px 0px 0px 10px",
              }}
            >
              <CardContent
                style={{ color: "white", borderRadius: "0px 10px 10px 0px" }}
              >
                <h1
                  style={{
                    fontFamily: "DM Serif Display",
                    fontWeight: 400,
                    fontSize: "24px",
                    marginTop: "30px",
                    marginBottom: "0px",
                  }}
                >
                  Contact Information
                </h1>
                <h5
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    fontSize: "14px",
                    marginTop: "15px",
                    maxWidth: "250px",
                  }}
                >
                  Fill up form and our team will get back to you within 12
                  hours.
                </h5>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
                >
                  <CallIcon style={{ color: "#e29a1b" }} />
                  <h5
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      fontSize: "15px",
                      paddingLeft: "20px",
                    }}
                  >
                    +1-905-462-2972
                  </h5>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AlternateEmailIcon style={{ color: "#e29a1b" }} />
                  <h5
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      fontSize: "15px",
                      paddingLeft: "20px",
                    }}
                  >
                    aman@awarecan.ca
                  </h5>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LocationOnIcon style={{ color: "#e29a1b" }} />
                  <h5
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      fontSize: "15px",
                      paddingLeft: "20px",
                    }}
                  >
                    Location
                  </h5>
                </div>
                <div style={{ display: "flex", marginTop: "150px" }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "39px",
                      width: "39px",
                      borderRadius: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "30px",
                    }}
                  >
                    <FacebookRoundedIcon
                      style={{
                        color: "#006994",
                        height: "45px",
                        width: "45px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "39px",
                      width: "39px",
                      borderRadius: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "30px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#006994",
                        height: "37px",
                        width: "37px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "39px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "27px",
                          width: "27px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "39px",
                        }}
                      >
                        <LinkedInIcon
                          style={{
                            color: "#006994",
                            height: "37px",
                            width: "37px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "39px",
                      width: "39px",
                      borderRadius: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid white",
                    }}
                  >
                    <TwitterIcon
                      style={{ color: "white", height: "32px", width: "32px" }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
          <Card sx={{ height: "600px", width: "847px" }}>
            <form>
              <div style={{ padding: "40px 40px 0px 40px", width: "100%" }}>
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  Full name
                </h1>
                <TextField
                  placeholder="John"
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                />
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  Email
                </h1>
                <TextField
                  placeholder="info@company.com"
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                />
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  Subject{" "}
                </h1>
                <TextField
                  placeholder="placeholder"
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                />
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  Message
                </h1>
                <TextField
                  placeholder="Write your short message"
                  multiline
                  rows={3}
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                />
              </div>
            </form>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "85%",
                padding: "20px 40px 40px 40px",
              }}
            >
              <MessageButton>Send Message</MessageButton>
            </div>
          </Card>
        </div>

        <Footer />
      </div>
    </>
  );
}
