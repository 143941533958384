import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Homepage from './Homepage'
import Details from "./Details"
import AboutUs from "./AboutUs"
import ContactUs from "./ContactUs"
export default function Routing() {
    // const location = useLocation();
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);
    return (
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/Details" element={<Details />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/ContactUs" element={<ContactUs />} />
        </Routes>
    )
}
