import img1 from "./assests/web-development-svgrepo-com.png"
import img2 from "./assests/mobile-app-developing-svgrepo-com.png"
import img3 from "./assests/nlp-natural-language-processing.png"
import img4 from "./assests/Mask group.png"
import img5 from "./assests/Custom Software Development.png"
import img6 from "./assests/web.png"
import img7 from "./assests/Digital Marketing.png"
import img8 from "./assests/chatbot.png"
import img9 from "./assests/Cloud Computing.png"
import pic1 from "./assests/trainer1.png"
import pic2 from "./assests/trainer2.png"
import pic3 from "./assests/trainer3.png"
import pic4 from "./assests/trainer4.png"
import pic5 from "./assests/trainer5.png"
import Dummy from "./assests/dummy.png"
import Amna from "./assests/Amna Hayyat.jpg"
import team1 from "./assests/team1.png"
import tp from "./assests/program.png"
import tp1 from "./assests/trainer.png"
import tp2 from "./assests/devlopmentProgram.jpg"
import tp3 from "./assests/industryIcon.jpg"
import tp4 from "./assests/diverstyIcon.jpg"
import tp5 from "./assests/projectM.jpg"
import ms from "./assests/socialMedia.png"
import ms1 from "./assests/SEO.png"
import ms2 from "./assests/Outreach.jpg"
import ms3 from "./assests/lead.png"
import ms4 from "./assests/networj.png"
import ms5 from "./assests/lead.png"
import ms6 from "./assests/CRM.jpg"
import ms7 from "./assests/salesf.png"
import cs from "./assests/crmim.png"
import cs1 from "./assests/buness.jpg"
import cs2 from "./assests/digital.png"
import cs3 from "./assests/markeet.jpg"
import cs4 from "./assests/bn.png"

export const objects = [
    {
        icon: img1,
        heading: "Web Development",
        subheading: "Crafting websites that captivate and convert visitors into loyal customers is our specialty."
    },
    {
        icon: img2,
        heading: "Mobile Development",
        subheading: "Expanding your digital reach with user-centric apps is where our expertise shines."
    },
    {
        icon: img3,
        heading: "NLP",
        subheading: "Empowering businesses through AI insights and smart chatbots is at the core of our capabilities."
    },
    {
        icon: img4,
        heading: "UI/UX Designing",
        subheading: "Our dedication lies in crafting visually appealing, user-friendly interfaces that deliver exceptional digital experiences."
    },
    {
        icon: img5,
        heading: "Custom Software Development ",
        subheading: "Driving innovation and operational efficiency with tailored solutions is what we do best."
    },
    {
        icon: img6,
        heading: "SaaS Development",
        subheading: "Our forte is transforming visionary concepts into scalable SaaS solutions in the cloud."
    },
    {
        icon: img7,
        heading: "Digital Marketing",
        subheading: "Achieving online marketing goals through data-driven strategies is our expertise."
    },
    {
        icon: img8,
        heading: "Intelligent Chat Systems",
        subheading: "Developing AI-powered chatbots for personalized support and task automation is our specialty."
    },
    {
        icon: img9,
        heading: "Cloud Engineering & Devops",
        subheading: "Ensuring high availability and agility by efficiently scaling and managing cloud applications is our focus."
    },
]

export const objectsLang = [
    {
        icon: img1,
        heading: "Développement Web",
        subheading: "Créer des sites Web qui captivent et convertissent les visiteurs en clients fidèles est notre spécialité."
    },
    {
        icon: img2,
        heading: "Développement mobile",
        subheading: "C’est dans l’expansion de votre portée numérique avec des applications centrées sur l’utilisateur que notre expertise brille."
    },
    {
        icon: img3,
        heading: "NLP",
        subheading: "L’autonomisation des entreprises grâce à des informations sur l’IA et à des chatbots intelligents est au cœur de nos capacités."
    },
    {
        icon: img4,
        heading: "Conception UI/UX",
        subheading: "Notre engagement réside dans la création d’interfaces visuellement attrayantes et conviviales qui offrent des expériences numériques exceptionnelles."
    },
    {
        icon: img5,
        heading: "Développement de logiciels sur mesure",
        subheading: "Stimuler l’innovation et l’efficacité opérationnelle avec des solutions sur mesure, c’est ce que nous faisons de mieux."
    },
    {
        icon: img6,
        heading: "Développement SaaS",
        subheading: "Notre point fort est de transformer des concepts visionnaires en solutions SaaS évolutives dans le cloud."
    },
    {
        icon: img7,
        heading: "Marketing numérique",
        subheading: "Atteindre les objectifs de marketing en ligne grâce à des stratégies axées sur les données est notre expertise."
    },
    {
        icon: img8,
        heading: "Systèmes de chat intelligents",
        subheading: "Le développement de chatbots alimentés par l’IA pour un support personnalisé et l’automatisation des tâches est notre spécialité."
    },
    {
        icon: img9,
        heading: "Ingénierie du cloud et Devops",
        subheading: "Notre objectif est d’assurer la haute disponibilité et l’agilité en mettant à l’échelle et en gérant efficacement les applications cloud."
    },
]

export const pages = ["About Us", "Contact Us"];
export const pagesLang = ["Qui sommes-nous", "Contactez-nous"];

export const TrainingPrograms = [
    {
        img: tp,
        heading: "Curriculum Development Programs",
        subheading: "Craft engaging and effective learning materials with our Curriculum Development Programs, designed to empower your team's educational prowess.",
    },
    {
        img: tp1,
        heading: "Train the Trainer Programs",
        subheading: "Empower your in-house experts to become exceptional educators with our Train the Trainer Programs, fostering a culture of knowledge sharing."
    },
    {
        img: tp2,
        heading: "Management Development Programs",
        subheading: "Nurture your leadership pipeline with our Management Development Programs, equipping your managers with the skills to drive success."
    },
    {
        img: tp3,
        heading: "Industry Specific Trainings",
        subheading: "Stay ahead in your industry with our specialized Industry Specific Trainings, tailored to the unique demands of your business sector."
    },
    {
        img: tp4,
        heading: "Equity, Diversity, and Inclusion Groups Training",
        subheading: "Promote a culture of inclusion, belonging, and understanding equality within your organization."
    },
    {
        img: tp5,
        heading: "Project Management Trainings",
        subheading: "Master the art of efficient project execution with our Project Management Trainings, ensuring your projects are delivered on time and within budget."
    },
]

export const TrainingProgramsLang = [
    {
        img: tp,
        heading: "Programmes d’élaboration de programmes d’études",
        subheading: "Créez des supports d’apprentissage attrayants et efficaces grâce à nos programmes d’élaboration de programmes d’études, conçus pour renforcer les prouesses éducatives de votre équipe.",
    },
    {
        img: tp1,
        heading: "Programmes de formation des formateurs",
        subheading: "Donnez à vos experts internes les moyens de devenir des éducateurs exceptionnels grâce à nos programmes de formation des formateurs, favorisant une culture de partage des connaissances."
    },
    {
        img: tp2,
        heading: "Programmes de perfectionnement des gestionnaires",
        subheading: "Nourrissez votre pipeline de leadership grâce à nos programmes de développement de la gestion, en dotant vos gestionnaires des compétences nécessaires pour réussir."
    },
    {
        img: tp3,
        heading: "Formations spécifiques à l’industrie",
        subheading: "Gardez une longueur d’avance dans votre secteur d’activité grâce à nos formations spécialisées spécifiques à l’industrie, adaptées aux exigences uniques de votre secteur d’activité."
    },
    {
        img: tp4,
        heading: "Formation des groupes sur l’équité, la diversité et l’inclusion",
        subheading: "Faites la promotion d’une culture d’inclusion, d’appartenance et de compréhension de l’égalité au sein de votre organisation."
    },
    {
        img: tp5,
        heading: "Formations en gestion de projet",
        subheading: "Maîtrisez l’art de l’exécution efficace des projets grâce à nos formations en gestion de projet, garantissant que vos projets sont livrés à temps et dans les limites du budget."
    },
]

export const Strategies = [
    {
        img: ms,
        heading: "Social Media Marketing",
        subheading: "Leading the Way in Digital Marketing Strategies",
    },
    {
        img: ms1,
        heading: "Search Engine Optimization",
        subheading: "Rank higher on search engines with our SEO expertise.",
    },
    {
        img: ms2,
        heading: "Outreach Programs",
        subheading: "Expand reach and connect with your audience through effective Outreach Programs.",
    },
    {
        img: ms3,
        heading: "Lead Generation",
        subheading: "Generate high-quality leads for business growth with our strategies.",
    },
    {
        img: ms4,
        heading: "Networking & Collaboration",
        subheading: "Unlock opportunities and partnerships with our services for growth."
    },
    {
        img: cs4,
        heading: "Media Strategy & Management",
        subheading: "Our Media Strategy & Management services are your ticket to standing out in the digital landscape. With our expertise, your brand can excel in today's dynamic media landscape.",
    },
    {
        img: ms5,
        heading: "Consulting Services",
        subheading: "Expert guidance to optimize strategies and solve business challenges with precision",
    },
    // {
    //     img: ms6,
    //     heading: "CRM Implementations",
    //     subheading: "Tailored CRM solutions for effective customer management, streamlined sales, and business growth.",
    // },
]

export const StrategiesLang = [
    {
        img: ms,
        heading: "Marketing des médias sociaux",
        subheading: "Ouvrir la voie aux stratégies de marketing numérique",
    },
    {
        img: ms1,
        heading: "Optimisation pour les moteurs de recherche",
        subheading: "Classez-vous plus haut sur les moteurs de recherche grâce à notre expertise SEO.",
    },
    {
        img: ms2,
        heading: "Programmes de sensibilisation",
        subheading: "Élargissez votre portée et connectez-vous avec votre public grâce à des programmes de sensibilisation efficaces.",
    },
    {
        img: ms3,
        heading: "Génération de leads",
        subheading: "Générez des prospects de haute qualité pour la croissance de votre entreprise grâce à nos stratégies.",
    },
    {
        img: ms4,
        heading: "Réseautage et collaboration",
        subheading: "Débloquez des opportunités et des partenariats avec nos services pour la croissance."
    },
    {
        img: cs4,
        heading: "Stratégie et gestion des médias",
        subheading: "Nos services de stratégie et de gestion des médias sont votre ticket pour vous démarquer dans le paysage numérique. Grâce à notre expertise, votre marque peut exceller dans le paysage médiatique dynamique d’aujourd’hui.",
    },
    {
        img: ms5,
        heading: "Services de conseil",
        subheading: "Des conseils d’experts pour optimiser les stratégies et résoudre les défis de l’entreprise avec précision",
    },
    // {
    //     img: ms6,
    //     heading: "Implémentations CRM",
    //     subheading: "Des solutions CRM sur mesure pour une gestion efficace des clients, des ventes rationalisées et la croissance de l’entreprise.",
    // },
]

export const Services = [
    {
        img: cs,
        heading: "CRM Implementation",
        subheading: "We empower you to better understand your clients, anticipate their needs, and provide personalized experiences.",
    },
    {
        img: cs1,
        heading: "Business Process Outsourcing",
        subheading: "By partnering with us for BPO, you gain efficiency, flexibility, and a competitive edge in today's dynamic business landscape."
    },
    {
        img: cs2,
        heading: "Digital Transformation",
        subheading: "We empower you to better understand your clients, anticipate their needs, and provide personalized experiences.",
    },
    {
        img: cs3,
        heading: "Market Research",
        subheading: "Our team dives deep into market trends, competitor analysis, and customer behaviour to provide you with a comprehensive understanding of your industry."
    },

]

export const ServicesLang = [
    {
        img: cs,
        heading: "Mise en œuvre d’un CRM",
        subheading: "Nous vous donnons les moyens de mieux comprendre vos clients, d’anticiper leurs besoins et d’offrir des expériences personnalisées.",
    },
    {
        img: cs1,
        heading: "Externalisation des processus d’affaires",
        subheading: "En vous associant à nous pour le BPO, vous gagnez en efficacité, en flexibilité et en avantage concurrentiel dans le paysage commercial dynamique d’aujourd’hui."
    },
    {
        img: cs2,
        heading: "Transformation numérique",
        subheading: "Nous vous donnons les moyens de mieux comprendre vos clients, d’anticiper leurs besoins et d’offrir des expériences personnalisées.",
    },
    {
        img: cs3,
        heading: "Étude de marché",
        subheading: "Notre équipe se penche sur les tendances du marché, l’analyse de la concurrence et le comportement des clients pour vous fournir une compréhension complète de votre secteur d’activité."
    },

]



export const Partners = [
    {
        img: Dummy,
        id: 10,
        name: "Omid Eshaghi",
        position: "Head Learning and Development",
        desc: `Omid Eshaghi is an esteemed figure in the realm of Learning and Development, boasting an illustrious career spanning over two decades. In his role as the Head of Learning and Development, Omid stands out for his visionary leadership. His primary focus centers on cultivating an organizational culture that champions employee development, skills enhancement, and talent retention. 
        Omid is renowned for his pioneering approach to training program design and delivery, harnessing the power of e-learning and technology-driven training methods. His advocacy for innovative learning management systems and online platforms has revolutionized employee training, enhancing efficiency and engagement.
        Omid Eshaghi is not only a mentor and coach but also a passionate advocate for continuous learning. He is actively involved in industry conferences, contributing thought leadership, webinars, and articles to disseminate the latest L&D trends and best practices. 

        Omid's impact transcends the professional sphere. He is deeply committed to corporate social responsibility, supporting educational charities and championing educational opportunities for underserved communities. His charismatic leadership and unwavering dedication to knowledge-sharing have left an indelible mark, inspiring individuals and organizations alike to reach new heights of success and learning.`,
        open: false,
        link: 'https://www.linkedin.com/in/omid-eshaghi/'

    },
    {
        img: Amna,
        id: 11,
        name: "Aamna Hayat, MA, RSSW",
        position: "COO Partner",
        desc: `Aamna Hayat is a distinguished Chief Operating Officer (COO) who has made significant contributions to her field. As a COO partner, Aamna plays a pivotal role in overseeing the day-to-day operations of her organization, ensuring its efficiency and growth. With her leadership and strategic vision, she guides the company toward achieving its goals and objectives.

        Aamna is renowned for her exceptional management skills, analytical prowess, and dedication to excellence. She excels in building strong teams, fostering collaboration, and optimizing processes to enhance overall productivity. Her leadership has been instrumental in driving innovation and adaptability, allowing her organization to thrive in dynamic and competitive markets.

        Aamna Hayat is a true trailblazer in the realm of business operations, and her commitment to achieving operational excellence sets her apart as an exemplary COO partner. Her impact on the business world is a testament to her leadership and unwavering dedication to driving success.`,
        open: false,
        link: 'https://www.linkedin.com/in/aamna-hayat-ma-rssw-68829a106/'
    },
]
export const PartnersLang = [
    {
        img: Dummy,
        id: 10,
        name: "Omid Eshaghi",
        position: "Head Learning and Development",
        desc: `Omid Eshaghi est une figure estimée dans le domaine de l’apprentissage et du développement, avec une illustre carrière qui s’étend sur plus de deux décennies. Dans son rôle de responsable de l’apprentissage et du développement, Omid se distingue par son leadership visionnaire. Son objectif principal est de cultiver une culture organisationnelle qui favorise le développement des employés, l’amélioration des compétences et la rétention des talents. Omid est réputé pour son approche pionnière de la conception et de la prestation de programmes de formation, exploitant la puissance de l’apprentissage en ligne et des méthodes de formation axées sur la technologie. Son plaidoyer en faveur de systèmes de gestion de l’apprentissage et de plateformes en ligne innovants a révolutionné la formation des employés, améliorant ainsi l’efficacité et l’engagement. Omid Eshaghi n’est pas seulement un mentor et un coach, mais aussi un ardent défenseur de l’apprentissage continu. Il participe activement à des conférences de l’industrie, contribuant à un leadership éclairé, à des webinaires et à des articles pour diffuser les dernières tendances et meilleures pratiques en matière de formation et de développement. L’impact d’Omid transcende la sphère professionnelle. Il est profondément engagé dans la responsabilité sociale des entreprises, en soutenant les organismes de bienfaisance dans le domaine de l’éducation et en défendant les possibilités d’éducation pour les communautés mal desservies.Son leadership charismatique et son dévouement indéfectible au partage des connaissances ont laissé une marque indélébile, inspirant des individus et des organisations à atteindre de nouveaux sommets de réussite et d’apprentissage.`,
        open: false,
        link: 'https://www.linkedin.com/in/omid-eshaghi/'

    },
    {
        img: Amna,
        id: 11,
        name: "Aamna Hayat, MA, RSSW",
        position: "COO Partner",
        desc: `Aamna Hayat est une chef de l’exploitation (COO) distinguée qui a apporté d’importantes contributions à son domaine. En tant que partenaire COO, Aamna joue un rôle central dans la supervision des opérations quotidiennes de son organisation, assurant son efficacité et sa croissance. Grâce à son leadership et à sa vision stratégique, elle guide l’entreprise vers l’atteinte de ses buts et objectifs. Aamna est réputée pour ses compétences exceptionnelles en gestion, ses prouesses analytiques et son dévouement à l’excellence. Elle excelle dans la constitution d’équipes solides, la promotion de la collaboration et l’optimisation des processus afin d’améliorer la productivité globale. Son leadership a joué un rôle déterminant dans l’innovation et l’adaptabilité, permettant à son organisation de prospérer dans des marchés dynamiques et concurrentiels. Aamna Hayat est une véritable pionnière dans le domaine des opérations commerciales, et son engagement envers l’excellence opérationnelle fait d’elle une partenaire exemplaire en matière de COO. Son impact sur le monde des affaires témoigne de son leadership et de son dévouement indéfectible à la réussite.`,
        open: false,
        link: 'https://www.linkedin.com/in/aamna-hayat-ma-rssw-68829a106/'
    },
]

export const Trainers = [
    {
        img: pic1,
        name: "Dr.Shahin Shariat, PhD",
        position: "Cloud Solution Architect",
        desc: `A distinguished British professional based near London, UK, is renowned for his expertise in 
        technical research, cloud architecture, and software solutions. His illustrious career spans pivotal roles showcasing 
        exceptional leadership and proficiency. 

        In academia, Shahin's brilliance shines. Armed with a Ph.D. in Electronic Engineering from the prestigious 5G Innovation 
        Centre (5GIC) at the University of Surrey, UK, he specialized in 5G Mobile and Satellite Communication. His academic 
        journey included a prestigious scholarship and close collaboration with influential 5G industrial partners. Shahin's 
        contributions reverberated with significance, pioneering a ground breaking routing protocol and publishing a pioneering 
        quantum game-theory protocol, revolutionizing telecommunications research. 
         
        Transitioning professionally, Shahin excelled. As a Senior Lead Architect at NTT Data UK&I, United Kingdom, he 
        orchestrated cross-functional teams, driving Cloud-native solutions. His visionary approach shaped cloud-based 
        strategies, showcasing the benefits of cloud adoption through proof-of-concept solutions. 
         
        As a Certified AWS Solution Architect Associate at Amazon Web Services (AWS), United Kingdom, Shahin's expertise was 
        invaluable. He provided meticulous technical consultation, conducted precise requirements gathering, and documented 
        cloud infrastructure solutions. His commitment to security and compliance was unwavering, conducting comprehensive 
        research on cloud technologies. 
         
        Shahin's journey extended to academia, as an instructor at the University of Surrey, United Kingdom. He enriched 
        students' experiences with expertise spanning analytics, data management, system optimizations, and programming, 
        earning acclaim for his instructional prowess. 
         
        In summary, Dr. Shahin Shariat's journey is marked by a relentless pursuit of excellence in academia and industry. His 
        profound contributions and instructional capabilities solidify his status as a highly accomplished professional in technical 
        research, cloud architecture, and software solutions, a testament to the transformative power of expertise, dedication, 
        and unwavering innovation.`,
        open: false,
        link: 'https://www.linkedin.com/in/shahin-architect/'

    },
    {
        img: pic2,
        name: "Sogand Shekarian",
        position: "Analytics and Production Management",
        desc: `Sogand Shekarian is a seasoned professional and expert instructor 
        in analytics and production management, green industry and research 
        management. Sogand holds a M.Eng. in Industrial Engineering from 
        Ryerson University, Toronto, Canada, and a Master’s degree from San 
        Jose State University. 
         
        With a strong academic background, Sogand has excelled as a Teaching 
        Assistant at Ryerson University, where she has independently taught 
        classes in analytics, research management, and various other subjects.  
        She has also thought multiple Industrial Engineering subjects at San 
        Jose State University. Her commitment to education shines through as 
        she provides comprehensive instruction to students and conducts 
        engaging tutorial sessions. 
         
        Sogand's proficiency is further underscored by her certifications, 
        including Lean Six Sigma (Green Belt) from San Jose State University 
        and Primavera Project Planner for Enterprise. Her extensive training 
        and hands-on experience make her a sought-after trainer and 
        instructor.`,
        open: false,
        link: 'https://www.linkedin.com/in/sogand-shekarian-a1568110a/'

    },
    {
        img: pic3,
        name: "Dr. Alireza Sharifi",
        position: "Business Management Trainer",
        desc: `Dr. Alireza Sharifi is a certified MasterClass Trainer and Master 
        Coach, multiple best-selling author and public speaker. He obtained his 
        doctorate degree in the field of work psychology and leadership 
        behavior. He has more than a decade of university and college 
        management and business teaching experience, as well as 8 years of 
        training and coaching experience in areas of Mindset and Emotional 
        Mastery, Leadership, and Soft-Skills Development. As a certified Stress 
        Management Consultant, NLP, Hypnotherapy, Time Line Therapy®, and 
        NLP Coaching trainer, he is backed by years of studies, and experience. 
        He worked with, trained, and coached , entrepreneurs, managers, and 
        leaders from various 
        industries around the world. His first book is called “Leadership in 
        Dynamic Organizations: Enhancing Performance, Creating Change”. He 
        also contributed to two best-selling books. “Canadian Success” was 
        published in October 2019; and his latest book chapter, “From Mindset 
        to Emotional Mastery”, is featured in the book “Healing”. Dr. Alireza 
        empowers people to create changes that last in their personal and 
        professional lives. His coaching allows clients to change mindsets and 
        emotions and equips them with essential skills through well-proven 
        and systematic methodologies in a short period of time.`,
        open: false,
        link: 'https://www.linkedin.com/'

    },
    {
        img: pic4,
        name: "Dr Farhad Azarmi",
        position: "Industrial / Environmental Trainer",
        desc: `Farhad boasts a Ph.D. in environmental and civil engineering, embodying a wealth of knowledge in green manufacturing, 
        clean energies, and sustainability. Prior to his industry engagement, his experience as a lecturer subsequent to his 
        doctorate underscored his adeptness in simplifying intricate concepts for effective communication – a skill integral to his 
        role as a workshop facilitator.  
        In his current capacity as a sustainability engineer at Sensient Technology, he spearheads worldwide initiatives ranging 
        from curbing carbon footprints and incorporating renewable energies to optimizing water usage and waste 
        management. His proficiency in technical report crafting ensures the clear conveyance of intricate insights, a 
        cornerstone for informed decision-making. His remarkable achievement includes shaping a comprehensive sustainability 
        strategy that not only raised Sensient Technology's ESG rating but also ensured compliance with SEC regulations. His 
        command of chemical engineering domains and his adept navigation of environmental standards such as OSHA, SASB, 
        TCFD, EPA, and GRI reflect his unwavering dedication to sustainability.  
        In addition to his vast academic knowledge and industrial experiences, his willingness to work together and his unending 
        determination to tackle problems make him a valuable asset to the team. He is at the forefront of both understanding 
        and striving towards making a difference in the field of sustainability and is ready to make significant contributions in 
        training professionals in various aspects of ESG.  
         
        SUGGESTED WORKSHOP TOPICS  
         
        - Sustainability in Manufacturing (Green Manufacturing): This workshop delves into the principles and practices of 
        integrating sustainability initiatives into manufacturing processes, focusing on resource efficiency, data collection and 
        carbon footprint analysis, mitigation strategies, waste management, water stewardship, eco-friendly technologies and 
        circular economy.  
         
        - Green Energy Integration: Explore the landscape of clean and renewable energy sources, elucidating the methods of 
        harnessing these energies for industrial operations, and the role they play in reducing the carbon footprint and future 
        trends in sustainable manufacturing.  
         
        - Creating a Culture of Sustainability: Examine strategies to instill a culture of sustainability within organizations, 
        fostering employee engagement and commitment to environmentally responsible practices.  
         
        - ESG/Sustainability Reporting and Compliance: Navigate the landscape of sustainability reporting frameworks, accurate 
        data collection, regulatory compliance, and ESG disclosures, guiding companies on how to transparently communicate 
        their sustainability efforts.  `,
        open: false,
        link: 'https://www.linkedin.com/'

    },
    {
        img: pic5,
        name: "Dr Amirpouyan Harandi",
        position: "Project Management Trainer",
        desc: `Amirpouyan Harandi holds a Ph.D. in Strategic Management, an MBA, and PMP certification, 
        showcasing his commitment to academic excellence and project management proficiency. With a 
        career spanning over 15 years, Amirpouyan has excelled in both academia and industry. 
        In academia, he has been a dedicated educator, teaching a wide range of courses at prestigious 
        institutions in Canada and Malaysia, including Yorkville University, Sheridan College, and 
        Cambrian College. Amirpouyan's extensive academic experience spans from lecturing in business 
        management to instructing courses on project management, marketing, and more. 
        Amirpouyan's influence extends beyond the classroom. He has also served as a lecturer in the 
        Organizational Development Program at the United Nations (YK Academy), Washington DC, 
        offering valuable insights into team building, negotiation skills, conflict resolution, strategic 
        management, and more. 
        His academic journey is further enriched by a notable publication record, with research articles 
        published in esteemed journals. 
        Complementing his academic contributions, Amirpouyan possesses a strong industry 
        background. He has held key roles in companies in Canada, Malaysia, and Iran, ranging from 
        Business System Analyst and System Architect to Senior Consultant and International Marketing 
        Manager. His responsibilities encompass project management, market analysis, business 
        planning, and more. 
        Amirpouyan Harandi's unique combination of academic and industry experience makes him a 
        well-rounded professional who has made significant contributions to the fields of business 
        management and project management. His dedication, knowledge, and commitment to 
        education and leadership are at the core of his successful career. `,
        open: false,
        link: 'https://www.linkedin.com/in/amirpouyan/'

    }

]

export const TrainersLang = [
    {
        img: pic1,
        name: "Dr.Shahin Shariat, PhD",
        position: "Cloud Solution Architect",
        desc: `Un éminent professionnel britannique basé près de Londres, au Royaume-Uni, est réputé pour son expertise dans les domaines suivants :
la recherche technique, l’architecture cloud et les solutions logicielles. Son illustre carrière s’étend sur des rôles clés mettant en vedette
Leadership et compétence exceptionnels. Dans le milieu universitaire, le génie de Shahin brille. Armé d’un doctorat en génie électronique de la prestigieuse 5G Innovation
(5GIC) à l’Université de Surrey, au Royaume-Uni, il s’est spécialisé dans la communication mobile et par satellite 5G. Son parcours académique
a bénéficié d’une bourse prestigieuse et d’une étroite collaboration avec des partenaires industriels influents de la 5G. Shahin’s
contributions ont eu un impact significatif, en mettant au point un protocole de routage révolutionnaire et en publiant un
Protocole de théorie quantique des jeux, révolutionnant la recherche sur les télécommunications. En transition professionnelle, Shahin a excellé. En tant qu’architecte principal chez NTT Data UK&I, au Royaume-Uni, il
orchestré des équipes interfonctionnelles, pilotant des solutions Cloud natives. Son approche visionnaire a façonné l’infonuagique
en mettant en valeur les avantages de l’adoption du cloud grâce à des solutions de preuve de concept. En tant qu’architecte de solutions AWS certifié associé chez Amazon Web Services (AWS), au Royaume-Uni, l’expertise de Shahin était
inestimable.Il a fourni des conseils techniques méticuleux, a procédé à une collecte précise des besoins et a documenté
solutions d’infrastructure cloud. Son engagement à l’égard de la sécurité et de la conformité était inébranlable, menant des
recherche sur les technologies infonuagiques. Le parcours de Shahin s’est étendu au milieu universitaire, en tant qu’instructeur à l’Université de Surrey, au Royaume-Uni. Il a enrichi
l’expérience des étudiants en matière d’expertise dans les domaines de l’analyse, de la gestion des données, de l’optimisation des systèmes et de la programmation ;
acclamé pour ses prouesses pédagogiques. En résumé, le parcours du Dr Shahin Shariat est marqué par une quête incessante de l’excellence dans le milieu universitaire et l’industrie. Son
Ses contributions profondes et ses capacités pédagogiques consolident son statut de professionnel hautement accompli dans le domaine de la
la recherche, l’architecture infonuagique et les solutions logicielles, ce qui témoigne du pouvoir transformateur de l’expertise, du dévouement,
et une innovation inébranlable`,
        open: false,
        link: 'https://www.linkedin.com/in/shahin-architect/'

    },
    {
        img: pic2,
        name: "Sogand Shekarian",
        position: "Analytics and Production Management",
        desc: `Sogand Shekarian est un instructeur professionnel et expert chevronné
dans l’analyse et la gestion de la production, l’industrie verte et la recherche
gestion. Sogand est titulaire d’une maîtrise en génie industriel de l’Université
Ryerson, Toronto, Canada, et une maîtrise de San Francisco
Université d’État de José. Forte d’une solide formation académique, Sogand a excellé en tant qu’enseignante
Assistante à l’Université Ryerson, où elle a enseigné de façon indépendante
cours d’analyse, de gestion de la recherche et de diverses autres matières. Elle a également étudié plusieurs sujets d’ingénierie industrielle à San Francisco.
Université d’État de José. Son engagement à l’égard de l’éducation transparaît dans les
Elle dispense un enseignement complet aux élèves et dirige des
Des séances de tutorat engageantes. La compétence de Sogand est encore soulignée par ses certifications,
y compris Lean Six Sigma (ceinture verte) de l’Université d’État de San Jose
et Primavera Project Planner for Enterprise. Sa formation approfondie
et son expérience pratique font d’elle une formatrice recherchée et
instructeur.`,
        open: false,
        link: 'https://www.linkedin.com/in/sogand-shekarian-a1568110a/'

    },
    {
        img: pic3,
        name: "Dr. Alireza Sharifi",
        position: "Business Management Trainer",
        desc: `Dr. Alireza Sharifi est un formateur certifié MasterClass et Master
Coach, auteur de plusieurs best-sellers et conférencier. Il a obtenu son
Doctorat dans le domaine de la psychologie du travail et du leadership
comportement. Il a plus d’une décennie d’études universitaires et collégiales
expérience en gestion et en enseignement des affaires, ainsi que 8 ans de
expérience de formation et de coaching dans les domaines de l’état d’esprit et de l’émotionnel
Maîtrise, leadership et développement des soft skills. En tant que Stress certifié
Consultant en gestion, NLP, hypnothérapie, thérapie® de la ligne du temps, et
Formateur en coaching NLP, il est soutenu par des années d’études et d’expérience. Il a travaillé, formé et coaché des entrepreneurs, des gestionnaires et des
dirigeants de divers
industries du monde entier. Son premier livre s’intitule « Leadership in
Organisations dynamiques : améliorer la performance, créer le changement ». Il
a également contribué à deux livres à succès. « Succès canadien » a été
publié en octobre 2019 ; et son dernier chapitre de livre, « From Mindset »
à la maîtrise émotionnelle », est présenté dans le livre « Guérison ». Dr. Alireza
permet aux gens de créer des changements qui durent dans leur vie personnelle et
vie professionnelle.Son coaching permet aux clients de changer les mentalités et
et les dote de compétences essentielles grâce à des compétences éprouvées
et des méthodologies systématiques dans un court laps de temps.`,
        open: false,
        link: 'https://www.linkedin.com/'

    },
    {
        img: pic4,
        name: "Dr Farhad Azarmi",
        position: "Industrial / Environmental Trainer",
        desc: `Farhad est titulaire d’un doctorat en génie civil et environnemental, qui incarne une richesse de connaissances dans le domaine de la fabrication verte.
les énergies propres et la durabilité. Avant de se joindre à l’industrie, son expérience en tant que chargé de cours
Son doctorat a souligné son habileté à simplifier des concepts complexes pour une communication efficace – une compétence qui fait partie intégrante de son
rôle d’animateur d’ateliers. Dans le cadre de ses fonctions actuelles d’ingénieur en développement durable chez Sensient Technology, il dirige des initiatives mondiales allant de
de la réduction de l’empreinte carbone à l’intégration des énergies renouvelables, en passant par l’optimisation de l’utilisation de l’eau et des déchets
gestion. Sa maîtrise de la rédaction de rapports techniques assure la transmission claire d’idées complexes, d’un
pierre angulaire d’une prise de décision éclairée. Parmi ses réalisations remarquables, citons l’élaboration d’un développement durable global
qui a non seulement permis d’améliorer la note ESG de Sensient Technology, mais aussi d’assurer la conformité aux réglementations de la SEC. Son
maîtrise des domaines du génie chimique et sa maîtrise des normes environnementales telles que l’OSHA, le SASB,
Le TCFD, l’EPA et la GRI reflètent son dévouement indéfectible à la durabilité.En plus de ses vastes connaissances académiques et de ses expériences industrielles, sa volonté de travailler ensemble et son inépuisable
Sa détermination à s’attaquer aux problèmes fait de lui un atout précieux pour l’équipe. Il est à l’avant-garde de la compréhension
s’efforce de faire la différence dans le domaine de la durabilité et est prêt à apporter des contributions significatives
la formation des professionnels aux différents aspects de l’ESG. SUJETS D’ATELIER SUGGÉRÉS
- Durabilité dans la fabrication (fabrication verte) : Cet atelier se penche sur les principes et les pratiques de
l’intégration d’initiatives de développement durable dans les processus de fabrication, en mettant l’accent sur l’efficacité des ressources, la collecte de données et
l’analyse de l’empreinte carbone, les stratégies d’atténuation, la gestion des déchets, la gestion de l’eau, les technologies écologiques et
économie circulaire. - Intégration de l’énergie verte : Explorez le paysage des sources d’énergie propres et renouvelables, en élucidant les méthodes de
l’exploitation de ces énergies pour les opérations industrielles, et le rôle qu’elles jouent dans la réduction de l’empreinte carbone et de l’avenir
tendances en matière de fabrication durable. - Créer une culture de la durabilité : Examiner les stratégies visant à inculquer une culture de la durabilité au sein des organisations.
Favoriser l’engagement des employés et l’engagement envers des pratiques respectueuses de l’environnement.- Rapports et conformité ESG/Sustainability : Naviguez dans le paysage des cadres de reporting sur le développement durable, avec précision
la collecte de données, la conformité réglementaire et les divulgations ESG, en guidant les entreprises sur la façon de communiquer de manière transparente
leurs efforts en matière de développement durable. `,
        open: false,
        link: 'https://www.linkedin.com/'

    },
    {
        img: pic5,
        name: "Dr Amirpouyan Harandi",
        position: "Project Management Trainer",
        desc: `Amirpouyan Harandi est titulaire d’un doctorat en gestion stratégique, d’un MBA et d’une certification PMP.
mettant en valeur son engagement envers l’excellence académique et ses compétences en gestion de projet. Avec un
Au cours de sa carrière de plus de 15 ans, Amirpouyan a excellé dans le milieu universitaire et dans l’industrie. Dans le milieu universitaire, il a été un éducateur dévoué, enseignant un large éventail de cours dans des établissements prestigieux.
au Canada et en Malaisie, y compris l’Université Yorkville, le Collège Sheridan et
Collège Cambrian. La vaste expérience académique d’Amirpouyan s’étend de l’enseignement des affaires
à l’enseignement de cours sur la gestion de projet, le marketing, etc. L’influence d’Amirpouyan s’étend au-delà de la salle de classe. Il a également été chargé de cours à l’Université de
Programme de développement organisationnel à l’Organisation des Nations Unies (YK Academy), Washington DC,
offrant des informations précieuses sur l’esprit d’équipe, les compétences en négociation, la résolution de conflits, les
gestion, et plus encore. Son parcours académique s’enrichit d’un remarquable dossier de publications, avec des articles de recherche
publiés dans des revues réputées. En plus de ses contributions académiques, Amirpouyan possède une industrie solide
arrière-plan.Il a occupé des postes clés au sein d’entreprises au Canada, en Malaisie et en Iran, notamment :
Analyste de systèmes d’affaires et architecte de systèmes à consultant senior et marketing international
Gérant. Ses responsabilités englobent la gestion de projet, l’analyse de marché, les affaires
planification, et plus encore. La combinaison unique d’expérience académique et industrielle d’Amirpouyan Harandi fait de lui un
Professionnel accompli qui a apporté une contribution importante dans le domaine des affaires
la gestion et la gestion de projet. Son dévouement, ses connaissances et son engagement à l’égard de
L’éducation et le leadership sont au cœur de sa brillante carrière.`,
        open: false,
        link: 'https://www.linkedin.com/in/amirpouyan/'

    }

]

export const Values = [
    {
        heading: "Transformational Marketing",
        subheading: " We are unwavering in our commitment to integrity, innovation, and excellence. These principles guide our every action and decision, fostering a culture of trust and collaboration.",
        img: "https://static.wixstatic.com/media/6a0a86_b844f401dfb74431bad0981f6e3713be~mv2.jpeg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Google-partners-image.jpeg"
    },
    {
        heading: "Community Engagement",
        subheading: " Anchored in unwavering integrity and relentless innovation, our values are the compass guiding us to excellence, fostering trust and collaboration. In addition, we wholeheartedly embrace sustainability, diversity, and inclusivity, ensuring a holistic and forward-thinking approach in all we do",
        img: 'https://static.wixstatic.com/media/6a0a86_99dda94bdf0a4ab4bb056a0613a7a8c3~mv2.jpg/v1/fill/w_640,h_400,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/6a0a86_99dda94bdf0a4ab4bb056a0613a7a8c3~mv2.jpg'
    },
    {
        heading: "Digital Growth Optimization",
        subheading: "Our values, deeply ingrained in our DNA, encompass unwavering integrity and a relentless pursuit of innovation. They steer our journey towards excellence and shape our commitment to fostering a culture of trust and collaboration.",
        img: 'https://static.wixstatic.com/media/6a0a86_ff610a6d096647489a0355395cb29148~mv2.jpg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/istockphoto-1312139041-612x612.jpg'
    },
    {
        heading: "Customer-Centric Approach",
        subheading: " Rooted in unwavering integrity and relentless innovation, our values drive us towards excellence, while fostering trust and collaboration. We also champion sustainability, diversity, and inclusivity as integral components of our guiding principles.",
        img: 'https://static.wixstatic.com/media/6a0a86_cbb8d81d89a74e0a89c907cad3b7efdd~mv2.jpg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/istockphoto-604021580-170667a.jpg'
    },
]

export const ValLang = [
    {
        heading: "Marketing transformationnel",
        subheading: "Nous sommes inébranlables dans notre engagement envers l’intégrité, l’innovation et l’excellence. Ces principes guident chacune de nos actions et de nos décisions, favorisant une culture de confiance et de collaboration.",
        img: "https://static.wixstatic.com/media/6a0a86_b844f401dfb74431bad0981f6e3713be~mv2.jpeg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Google-partners-image.jpeg"
    },
    {
        heading: "Engagement communautaire",
        subheading: "Ancrées dans une intégrité inébranlable et une innovation incessante, nos valeurs sont la boussole qui nous guide vers l’excellence, favorisant la confiance et la collaboration. De plus, nous adhérons de tout cœur à la durabilité, à la diversité et à l’inclusion, garantissant une approche holistique et avant-gardiste dans tout ce que nous faisons",
        img: 'https://static.wixstatic.com/media/6a0a86_99dda94bdf0a4ab4bb056a0613a7a8c3~mv2.jpg/v1/fill/w_640,h_400,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/6a0a86_99dda94bdf0a4ab4bb056a0613a7a8c3~mv2.jpg'
    },
    {
        heading: "Optimisation de la croissance numérique",
        subheading: "Nos valeurs, profondément ancrées dans notre ADN, englobent une intégrité inébranlable et une recherche incessante d’innovation. Ils orientent notre cheminement vers l’excellence et façonnent notre engagement à favoriser une culture de confiance et de collaboration.",
        img: 'https://static.wixstatic.com/media/6a0a86_ff610a6d096647489a0355395cb29148~mv2.jpg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/istockphoto-1312139041-612x612.jpg'
    },
    {
        heading: "Approche centrée sur le client",
        subheading: "Enracinées dans une intégrité inébranlable et une innovation incessante, nos valeurs nous poussent vers l’excellence, tout en favorisant la confiance et la collaboration. Nous défendons également la durabilité, la diversité et l’inclusion en tant que composantes intégrales de nos principes directeurs.",
        img: 'https://static.wixstatic.com/media/6a0a86_cbb8d81d89a74e0a89c907cad3b7efdd~mv2.jpg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/istockphoto-604021580-170667a.jpg'
    },
]

export const Team = [
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },
    {
        img: team1,
        name: "Åsa Nyström",
        job: "VP of Customer Advocacy",
        location: "Sydney, Australia"
    },

]
