import { BrowserRouter } from "react-router-dom";
import Routing from "./Routes";
import AppContextProvider from "./context/AppContext";

function App() {

  return (
    <>
      <AppContextProvider>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </AppContextProvider>
    </>
  );
}

export default App;
