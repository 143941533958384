import React from 'react'
import "./styles.css"
import { Link, useNavigate } from 'react-router-dom'
import { useApp } from '../context/AppContext';

export default function DropDown() {
    const navigate = useNavigate();
    const { language } = useApp()

    const scrollToWebDevelopment = (id) => {

        navigate('/')
        setTimeout(() => {
            document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
        }, 300)

    }

    return (
        <div className='menu'>
            <ul className='ul'>
                <li className='mainBtn'>
                    {language == 'en' ? `Tech` : `Tech`}
                    <ul>
                        <li class="link">
                            <Link to='/#webDevelopment' className='mainBtn' onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }}>{language == 'en' ? `Web Development` : `Développement Web`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#webDevelopment" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }}>{language == 'en' ? `Mobile Development` : `Développement mobile`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#webDevelopment" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }}>NLP</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#webDevelopment" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }}>{language == 'en' ? `UI/UX Designing` : `Conception UI/UX`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#webDevelopment" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }}>{language == 'en' ? `Custom Software Development` : `Développement de logiciels sur mesure`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#webDevelopment" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }}>{language == 'en' ? `SaaS Development` : `Développement SaaS`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#webDevelopment" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }}>{language == 'en' ? `Digital Marketing` : `Marketing numérique`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#webDevelopment" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }}>{language == 'en' ? `Intelligent Chat Systems` : `Systèmes de chat intelligents`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#webDevelopment" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("webDevelopment") }}>{language == 'en' ? `Cloud Engineering & Devops` : `Ingénierie du cloud et Devops`}</Link>
                        </li>
                    </ul>
                </li>
                <li className='mainBtn'>
                    {language == 'en' ? `Training` : `Formation`}
                    <ul>
                        <li class="link">
                            <Link className='mainBtn' to="/#CurriculumDevelopmentPrograms" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }}>{language == 'en' ? `Curriculum Development Programs` : `Programmes d’élaboration de programmes d’études`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#CurriculumDevelopmentPrograms" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }}>{language == 'en' ? `Train the Trainer Programs` : `Programmes de formation des formateurs`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#CurriculumDevelopmentPrograms" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }}>{language == 'en' ? `Management Development Programs` : `Programmes de perfectionnement des gestionnaires`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#CurriculumDevelopmentPrograms" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }}>{language == 'en' ? `Industry Specific Trainings` : `Formations spécifiques à l’industrie`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#CurriculumDevelopmentPrograms" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }}>{language == 'en' ? `Equity, Diversity, and Inclusion Groups Training` : `Formation des groupes sur l’équité, la diversité et l’inclusion`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#CurriculumDevelopmentPrograms" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("CurriculumDevelopmentPrograms") }}>{language == 'en' ? `Project Management Trainings` : `Formations en gestion de projet`}</Link>
                        </li>
                    </ul>

                </li>
                <li className='mainBtn'>
                    {language == 'en' ? `Digital` : `Numérique`}
                    <ul>
                        <li class="link">
                            <Link className='mainBtn' to="/#DigitalMarketing" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }}>{language == 'en' ? `Social Media Marketing` : `Marketing des médias sociaux`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#DigitalMarketing" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }}>{language == 'en' ? `Search Engine Optimization` : `Optimisation pour les moteurs de recherche`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#DigitalMarketing" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }}>{language == 'en' ? `Outreach Programs` : `Programmes de sensibilisation`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#DigitalMarketing" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }}>{language == 'en' ? `Lead Generation` : `Génération de leads`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#DigitalMarketing" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }}>{language == 'en' ? `Networking & Collaboration` : `Réseautage et collaboration`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#DigitalMarketing" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }}>{language == 'en' ? `Media Strategy & Management` : `Stratégie et gestion des médias`}</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#DigitalMarketing" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }}>{language == 'en' ? `Consulting Services` : `Services de conseil`}</Link>
                        </li>
                        {/* <li class="link">
                            <Link className='mainBtn' to="/#DigitalMarketing" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }}>{language == 'en' ? `CRM Implementations` : `Implémentations CRM`}</Link>
                        </li> */}
                        {/* <li class="link">
                            <Link className='mainBtn' to="/#DigitalMarketing" onClick={(e) => { e.preventDefault(); scrollToWebDevelopment("DigitalMarketing") }}>Salesforce Implementation</Link>
                        </li> */}
                    </ul>
                </li>
                {/* 
                <li className='mainBtn'>
                    Digital Solution
                    <ul>
                        <li class="link">
                            <Link className='mainBtn' to="/#CurriculumDevelopmentPrograms" onClick={() => scrollToWebDevelopment("CurriculumDevelopmentPrograms")}>Curriculum Development Programs</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#TraintheTrainerPrograms" onClick={() => scrollToWebDevelopment("TraintheTrainerPrograms")}>Train the Trainer Programs</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#ManagementDevelopmentPrograms" onClick={() => scrollToWebDevelopment("ManagementDevelopmentPrograms")}>Management Development Programs</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#IndustrySpecificTrainings" onClick={() => scrollToWebDevelopment("IndustrySpecificTrainings")}>Industry Specific Trainings</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#EquityDiversityInclusionGroupsTraining" onClick={() => scrollToWebDevelopment("EquityDiversityInclusionGroupsTraining")}>Equity, Diversity, and Inclusion Groups Training</Link>
                        </li>
                        <li class="link">
                            <Link className='mainBtn' to="/#ProjectManagementTrainings" onClick={() => scrollToWebDevelopment("ProjectManagementTrainings")}>Project Management Trainings</Link>
                        </li>
                    </ul>
                </li> */}
                {/* <li className='mainBtn'>
                            Marketing Strategies
                            <ul>
                                <li class="link">
                                    <Link className='mainBtn' to="/#SocialMediaMarketing" onClick={() => scrollToWebDevelopment("SocialMediaMarketing")}>Social Media Marketing</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#SearchEngineOptimization" onClick={() => scrollToWebDevelopment("SearchEngineOptimization")}>Search Engine Optimization</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#OutreachPrograms" onClick={() => scrollToWebDevelopment("OutreachPrograms")}>Outreach Programs</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#LeadGeneration" onClick={() => scrollToWebDevelopment("LeadGeneration")}>Lead Generation</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#NetworkingCollaboration" onClick={() => scrollToWebDevelopment("NetworkingCollaboration")}>Networking & Collaboration</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#BusinessProcessAutomation" onClick={() => scrollToWebDevelopment("BusinessProcessAutomation")}>Business Process Automation</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#CRMImplementations" onClick={() => scrollToWebDevelopment("CRMImplementations")}>CRM Implementations</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#SalesforceImplementation" onClick={() => scrollToWebDevelopment("SalesforceImplementation")}>Salesforce Implementation</Link>
                                </li>
                            </ul>
                        </li>
                        <li className='mainBtn'>
                            Consulting Services
                            <ul>
                                <li class="link">
                                    <Link className='mainBtn' to="/#CRMImplementation" onClick={() => scrollToWebDevelopment("CRMImplementation")}>CRM Implementation</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#BusinessProcessOutsourcing" onClick={() => scrollToWebDevelopment("BusinessProcessOutsourcing")}>Business Process Outsourcing</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#DigitalTransformation" onClick={() => scrollToWebDevelopment("DigitalTransformation")}>Digital Transformation</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#MarketResearch" onClick={() => scrollToWebDevelopment("MarketResearch")}>Market Research</Link>
                                </li>
                                <li class="link">
                                    <Link className='mainBtn' to="/#MediaStrategyManagement" onClick={() => scrollToWebDevelopment("MediaStrategyManagement")}>Media Strategy & Management</Link>
                                </li>
                            </ul>
                        </li> */}

            </ul>
        </div>
    )
}
