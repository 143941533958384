
import React, { createContext } from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
export const AppContext = createContext();

export default function AppContextProvider({ children }) {
    const [language, setLanguage] = useState('en')

    const checkLang = async () => {
        const languageLocal = localStorage.getItem("lang") || "en"
        setLanguage(languageLocal)
    }

    useEffect(() => {
        checkLang();
    }, [])

    return (
        <AppContext.Provider value={{ language, setLanguage }}>
            {children}
        </AppContext.Provider>
    )
}

export const useApp = () => useContext(AppContext)