import React, { useState } from "react";
import cover1 from "../assests/cover1.png";
import bg from "../assests/BG.png";
import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { Team, Trainers, Values } from "../constants";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { SocialIcon } from "react-social-icons";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import Navbar from "../Navbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Rectangle from "../assests/Rectangle.png";
import Footer from "../Footer";
import "./index.css"
import { useApp } from "../context/AppContext";
const MessageButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: "24px",
  backgroundColor: "#0062F2",
  borderColor: "white",
  fontFamily: "Open Sans",
  color: "white",
  '&:hover': {
    backgroundColor: "#0062F2",
    color: "white",
  }
});

export default function Index() {
  const { language } = useApp()
  const theme = useTheme();
  const isDestop = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Navbar />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          id="first div"
          style={{
            backgroundImage: `url(${cover1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            width: "100%",
            height: "450px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div className="contactUsHeading"
            style={{
              textAlign: "left",
              width: "700px",
              marginLeft: "80px",
            }}

          >
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontWeight: "400",
                fontSize: "62px",
                color: "white",
              }}
            >
              {language == 'en' ? "Contact Us" : "Contactez-nous"}
            </h1>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "60px",
            marginBottom: "60px",
          }}
          className="contactUs"
        >
          {!isDestop && (
            <Card
              sx={{
                height: "625px",
                width: "433px",
                backgroundImage: `url(${bg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                borderRadius: "10px 0px 0px 10px",
              }}
            >
              <CardContent
                style={{ color: "white", borderRadius: "0px 10px 10px 0px" }}
              >
                <h1
                  style={{
                    fontFamily: "DM Serif Display",
                    fontWeight: 400,
                    fontSize: "24px",
                    marginTop: "30px",
                    marginBottom: "0px",
                  }}
                >
                  {language == 'en' ? `Contact Information` : `Coordonnées`}
                </h1>
                <h5
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    fontSize: "14px",
                    marginTop: "15px",
                    maxWidth: "250px",
                  }}
                >
                  {language == 'en' ? `Fill up form and our team will get back to you within 12
                  hours.`: `Remplissez le formulaire et notre équipe vous répondra dans les 12H.`}
                </h5>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
                >
                  <CallIcon style={{ color: "#e29a1b" }} />
                  <h5
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      fontSize: "15px",
                      paddingLeft: "20px",
                    }}
                  >
                    +1-905-462-2972
                  </h5>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AlternateEmailIcon style={{ color: "#e29a1b" }} />
                  <h5
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      fontSize: "15px",
                      paddingLeft: "20px",
                    }}
                  >
                    aman@awarecan.ca
                  </h5>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LocationOnIcon style={{ color: "#e29a1b" }} />
                  <h5
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      fontSize: "15px",
                      paddingLeft: "20px",
                    }}
                  >
                    Awarecan Inc Unit 503-407 Dundas Street West, Oakville, ON
                    {/* {language == 'en' ? `Location` : `Emplacement`} */}
                  </h5>
                </div>
                <div style={{ display: "flex", marginTop: "150px" }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "39px",
                      width: "39px",
                      borderRadius: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "30px",
                      cursor: "pointer"

                    }}
                  >
                    <FacebookRoundedIcon
                      style={{
                        color: "#0062F2",
                        height: "45px",
                        width: "45px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "39px",
                      width: "39px",
                      borderRadius: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "30px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#0062F2",
                        height: "37px",
                        width: "37px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "39px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "27px",
                          width: "27px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "39px",
                          cursor: "pointer"

                        }}
                      >
                        <LinkedInIcon
                          style={{
                            color: "#0062F2",
                            height: "37px",
                            width: "37px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "39px",
                      width: "39px",
                      borderRadius: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid white",
                      cursor: "pointer"
                    }}
                  >
                    <TwitterIcon
                      style={{ color: "white", height: "32px", width: "32px" }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
          <Card sx={{ height: "625px", width: "847px" }}>
            <form method="POST" action="https://formsubmit.co/aman@awarecan.ca">
              <div style={{ padding: "40px 30px 0px 25px", width: "100%" }}>
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  {language == 'en' ? `Full name` : `Nom complet`}
                </h1>
                <TextField
                  placeholder="John"
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                  name="name"
                  required
                />
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  {language == 'en' ? `Email` : `Courriel`}
                </h1>
                <TextField
                  placeholder="info@company.com"
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                  name="email"
                  required
                />
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  {language == 'en' ? `Subject` : `Objet`}{" "}
                </h1>
                <TextField
                  placeholder="placeholder"
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                  name="subject"
                  required
                />
                <h1
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  {language == 'en' ? `Message` : `Message`}
                </h1>
                <TextField
                  placeholder="Write your short message"
                  multiline
                  rows={3}
                  variant="standard"
                  style={{ width: "85%", marginBottom: "25px" }}
                  name="message"
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "85%",
                  padding: "20px 40px 40px 40px",
                }}
              >
                <MessageButton type="submit">{language == 'en' ? `Send Message` : `Envoyer un message`}</MessageButton>
              </div>
            </form>
          </Card>
        </div>
        <Footer />
      </div>
    </>
  );
}
