import React, { useEffect, useState } from "react";
import cover1 from "../assests/cover1.png";
import bg from "../assests/BG.png";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Partners, PartnersLang, Team, Trainers, TrainersLang, ValLang, Values, ValuesLang } from "../constants";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useApp } from "../context/AppContext";
import { SocialIcon } from "react-social-icons";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import Navbar from "../Navbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Rectangle from "../assests/Rectangle.png";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assests/removeBg.png"
import "./index.css"
import { Language } from "@mui/icons-material";


const CustomButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 25px',
  lineHeight: "24px",
  color: "white",
  backgroundColor: '#0062F2',
  fontFamily: "Open Sans",
  "&:hover": {
    backgroundColor: "blue",
  },
});

function SimpleDialog({ open, onClose, name, txt, img, href }) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        About {name}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          <div style={{ justifyContent: "center", alignItems: "center", display: "flex", marginBottom: "13px" }}>
            <img src={img} alt="at img" height="296px" width="296px" style={{ borderRadius: 8 }} />
          </div>
          <h3 style={{ padding: 0, margin: 0 }}>Introduction:</h3>
          <p style={{ textAlign: "justify", padding: 0, margin: 0 }}>{txt}</p><br />
          <h3 style={{ padding: 0, margin: 0 }}>Social Media:</h3>
          <a href={href} target="_blank">
            <LinkedInIcon style={{ height: "24px", width: "24px", marginRight: "10px", color: "#98a2b3", cursor: "pointer" }} />
          </a>
        </Typography>
      </DialogContent>
    </Dialog >
  )
}
export default function Index() {
  const { language, setLanguage } = useApp()
  const [TrainersState, setTrainersState] = useState(language === "en" ? Trainers : TrainersLang)
  const [PartnersState, setPatners] = useState(language === "en" ? Partners : PartnersLang)
  const theme = useTheme();
  const isDestop = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  // const Trains = 
  useEffect(() => {
    setTrainersState(language === "en" ? Trainers : TrainersLang)
    setPatners(language === "en" ? Partners : PartnersLang)
  }, [language])

  const handleClose = (index) => {
    const updatedTrainers = [...TrainersState];
    updatedTrainers[index].open = false;
    setTrainersState(updatedTrainers);
  };
  const handleClickOpen = (index) => {
    const updatedTrainers = [...TrainersState];
    updatedTrainers[index].open = true;
    setTrainersState(updatedTrainers);
  };


  // this is for partners=====================
  const handleCloseP = (index) => {
    const updatedPartners = [...PartnersState];
    updatedPartners[index].open = false;
    setPatners(updatedPartners);
  };
  const handleClickOpenP = (index) => {
    const updatedPartners = [...PartnersState];
    updatedPartners[index].open = true;
    setPatners(updatedPartners);
  };

  const handleLanChange = (lang) => {
    localStorage.setItem('lang', lang)
    setLanguage(lang)

  }

  const ValuesLang = language === "en" ? Values : ValLang
  return (
    <>
      <Navbar />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          id="first div"
          style={{
            backgroundImage: `url(${cover1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            width: "100%",
            height: "450px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              textAlign: "left",
              width: "700px",
              marginLeft: "80px",
            }}
          >
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontWeight: "400",
                fontSize: "62px",
                color: "white",
              }}
            >
              {language == 'en' ? `About Us` : `Qui sommes-nous`}
            </h1>
          </div>
        </div>

        <div style={{ marginTop: "100px" }} className="mainSection">
          <Grid
            container
            justifyContent={"center"}
            spacing={10}
            columns={{ xs: 1, sm: 1, md: 2, lg: 2 }}
          >
            <Grid item className="mainSection">
              <img src={Rectangle} alt="images" className="mainContentImges" />
            </Grid>
            <Grid item>
              <div style={{ textAlign: "Left", width: "575px" }} className="mainContent">
                <p
                  style={{
                    fontFamily: "DM Serif Display",
                    fontSize: "40px",
                    fontWeight: 400,
                    lineHeight: "48px",
                  }}
                >
                  {language == 'en' ? `Our Story` : `Notre histoire`}
                </p>
                <span
                  style={{
                    color: "#545454",
                    fontFamily: "Open Sans",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "37px",
                    fontStyle: "italic",
                  }}
                >
                  {language == 'en' ? `At AwareCan, we empower small and medium-sized businesses to thrive through development robust and adaptable marketing ecosystem.` : `Chez AwareCan, nous donnons aux petites et moyennes entreprises les moyens de prospérer grâce au développement d’un écosystème marketing robuste et adaptable.`}
                  <br />

                  {language == 'en' ? `Our approach encompasses precise measurement, rigorous testing, and continual iteration.` : `Notre approche englobe des mesures précises, des tests rigoureux et une itération continue.`}
                  <br />
                  {language == 'en' ? `This powerful transformation is achieved by merginQui sommes-nousg our strong financial and business expertise with advanced data analysis and a profound grasp of cutting-edge technologies.` : `Cette transformation puissante est le fruit de la fusion de notre solide expertise financière et commerciale avec une analyse avancée des données et une compréhension approfondie des technologies de pointe.`}
                  <br />
                  {language == 'en' ? `Our business philosophy revolves around fostering active community engagement, adeptly managing social media, and optimizing digital avenues to drive our clients' growth to new heights.
                  With a dynamic and multifaceted strategy, we help businesses flourish in an ever-evolving landscape.`: `Notre philosophie d’entreprise s’articule autour de la promotion de l’engagement actif de la communauté, de la gestion habile des médias sociaux et de l’optimisation des avenues numériques pour propulser la croissance de nos clients vers de nouveaux sommets. Grâce à une stratégie dynamique et multidimensionnelle, nous aidons les entreprises à prospérer dans un paysage en constante évolution.`}
                </span>
              </div>
            </Grid>
          </Grid>
        </div>

        <div
          style={{ width: "100%", textAlign: "center", overflowX: "hidden" }}
        >
          <div className="value" style={{ marginBottom: "35px" }}>
            <h1
              style={{
                fontFamily: "DM Serif Display",
                fontSize: "40px",
                fontWeight: 400,
                color: "#090909",
                marginBottom: "20px",
                marginTop: "60px",
              }}
            >
              {language == 'en' ? `Our Values` : `Nos valeurs`}
            </h1>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Open Sans",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",
                color: "#545454",
                // marginBottom: "60px",
                margin: "0px 100px",

              }}
              className="smPara"
            >
              {language == 'en' ? `At AwareCan, we empower small and medium-sized businesses to thrive through the development of a robust and adaptable marketing ecosystem.` : `Chez AwareCan, nous donnons aux petites et moyennes entreprises les moyens de prospérer grâce au développement d’un écosystème marketing robuste et adaptable.`}<br />
              {language == 'en' ? `Our approach encompasses precise measurement, rigorous testing, and continual iteration.` : `Notre approche englobe des mesures précises, des tests rigoureux et une itération continue.`}
            </p>
          </div>


          <Grid container justifyContent={"center"} spacing={10}>
            {ValuesLang?.map((entry) => (
              <Grid item>
                <div className="smCards" style={{ textAlign: "left", maxWidth: "400px", margin: "0px 50px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <img
                      src={`${entry.img}`}
                      style={{
                        width: "42px",
                        height: "42px",
                      }}
                    >

                    </img>
                    <h1
                      style={{
                        fontFamily: "DM Serif Display",
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "27px",
                        marginLeft: "10px"
                      }}
                    >
                      {entry.heading}
                    </h1>
                  </div>
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "17px",
                      fontWeight: 400,
                      color: "#545454",
                      marginTop: "20px",
                    }}
                  >
                    {entry.subheading}
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>


        <div className="smAbout" style={{ margin: "30px 50px 0px 140px" }}>
          {/* Partners Section */}
          <h2 style={{ fontFamily: "DM Serif Display", fontSize: "32px", lineHeight: "40px" }}>Partners</h2>
          <Grid container style={{ marginBottom: "0px" }}>
            {PartnersState?.map((trainer, index) => (
              <Grid item key={index} style={{ textAlign: "left", width: "296px", marginRight: "30px", marginBottom: "70px", cursor: "pointer" }}>
                <div onClick={() => handleClickOpenP(index)}>
                  <div style={{ height: "296px", width: "296px", borderRadius: "25px", backgroundColor: "lightgrey", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={trainer.img} alt="at img" height="296px" width="296px" />
                  </div>
                  <p style={{ fontFamily: "DM Serif Display", fontSize: "20px", lineHeight: "30px" }}>{trainer.name}</p>
                  <p style={{ fontFamily: "Open Sans", fontSize: "18px", lineHeight: "28px", color: ' #0062F2' }}>{trainer.position}</p>
                  <p style={{ fontFamily: "Open Sans", fontSize: "16px", lineHeight: "24px", color: "#7a7a7a", cursor: "pointer" }}>{trainer.desc.split(/\s+/).slice(0, 10).join(" ")}...</p>
                </div>
                <div style={{ display: "flex" }}>
                  <a href={trainer.link} target="_blank">
                    <LinkedInIcon style={{ height: "24px", width: "24px", marginRight: "10px", color: "#98a2b3" }} />
                  </a>
                </div>
                <SimpleDialog open={trainer.open} onClose={() => handleCloseP(index)} name={trainer.name} txt={trainer.desc} img={trainer.img} href={trainer.link} />
              </Grid>
            ))}
          </Grid>

          {/* Advisory Council Section */}
          <h2 style={{ fontFamily: "DM Serif Display", fontSize: "32px", lineHeight: "40px" }}>Advisory Council</h2>
          <Grid container style={{ marginBottom: "0px" }}>
            {TrainersState?.map((trainer, index) => (
              <Grid item key={index} style={{ textAlign: "left", width: "296px", marginRight: "30px", marginBottom: "70px", cursor: "pointer" }}>
                <div onClick={() => handleClickOpen(index)}>
                  <div style={{ height: "296px", width: "296px", borderRadius: "25px", backgroundColor: "lightgrey", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={trainer.img} alt="at img" height="296px" width="296px" />
                  </div>
                  <p style={{ fontFamily: "DM Serif Display", fontSize: "20px", lineHeight: "30px" }}>{trainer.name}</p>
                  <p style={{ fontFamily: "Open Sans", fontSize: "18px", lineHeight: "28px", color: ' #0062F2' }}>{trainer.position}</p>
                  <p style={{ fontFamily: "Open Sans", fontSize: "16px", lineHeight: "24px", color: "#7a7a7a", cursor: "pointer" }}>{trainer.desc.split(/\s+/).slice(0, 10).join(" ")}...</p>
                </div>
                <div style={{ display: "flex" }}>
                  <a href={trainer.link} target="_blank">
                    <LinkedInIcon style={{ height: "24px", width: "24px", marginRight: "10px", color: "#98a2b3" }} />
                  </a>
                </div>
                <SimpleDialog open={trainer.open} onClose={() => handleClose(index)} name={trainer.name} txt={trainer.desc} img={trainer.img} href={trainer.link} />
              </Grid>
            ))}
          </Grid>
        </div>
        <div
          style={{ width: "100%", textAlign: "center", overflowX: "hidden", backgroundColor: "#f9f9f9" }}
        >
          <h1
            style={{
              fontFamily: "DM Serif Display",
              fontSize: "40px",
              fontWeight: 400,
              color: "#090909",
              marginBottom: "20px",
              marginTop: "100px",
            }}
          >
            {language == 'en' ? `Want to join us?` : `Envie de nous rejoindre ?`}
          </h1>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Open Sans",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "32px",
              color: "#545454",
              marginBottom: "60px",

            }}
          >
            {language == 'en' ? `Join us in creating engaging content. Be part of our community today.?` : `Rejoignez-nous pour créer un contenu captivant. Faites partie de notre communauté dès aujourd'hui`}

          </p>
          <CustomButton style={{ marginBottom: "100px" }} onClick={() => navigate("/ContactUs")}>{language == 'en' ? "Contact Us" : "Contactez-nous"}</CustomButton>
        </div>

        <div
          style={{ width: "100%", backgroundColor: "#0062F2" }}
        >
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: isDestop ? "40px" : "100px",
            }}
          >
            <Grid
              item
              xs={12}
              md={3}
              // lg={2}
              style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <Link to='/' style={{ height: "37px", width: "134px" }}>
                <img src={logo} alt="logo img" />
              </Link>
              <p style={{ color: "white", marginLeft: "15px" }}>
                Awarecan Inc Unit 503-407 Dundas Street West Oakville ON
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                <p
                  style={{
                    marginRight: "80px",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    color: "white",
                    fontWeight: 400,
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate('/AboutUs')
                  }}
                >
                  {language == 'en' ? `About Us` : `Qui sommes-nous`}
                </p>
                <p
                  style={{
                    marginRight: "80px",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    color: "white",
                    fontWeight: 400,
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate('/ContactUs')
                  }}
                >
                  {language == 'en' ? "Contact Us" : "Contactez-nous"}
                </p>
                <div class="select">
                  <select id="standard-select" value={language} onChange={(e) => handleLanChange(e.target.value)}>
                    <option value={'en'} className='opt'>English</option>
                    <option value={'fr'} className='opt'>French</option>
                  </select>
                </div>
                {/* <p
                  style={{
                    marginRight: "80px",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    color: "white",
                    fontWeight: 400,
                  }}
                >
                  Blogs
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    color: "white",
                    fontWeight: 400,
                  }}
                >
                  More
                </p> */}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ display: "flex" }}>
                <SocialIcon
                  url="https://twitter.com"
                  bgColor="black"
                  style={{ height: "28px", width: "28px", marginRight: "10px" }}
                />
                <SocialIcon
                  url="https://facebook.com"
                  bgColor="#e29a1b"
                  style={{ height: "28px", width: "28px", marginRight: "10px" }}
                />
                <SocialIcon
                  url="https://instagram.com"
                  bgColor="black"
                  style={{ height: "28px", width: "28px", marginRight: "10px" }}
                />
                {/* <SocialIcon
                  url="https://github.com"
                  bgColor="black"
                  style={{ height: "28px", width: "28px" }}
                /> */}
              </div>
            </Grid>
          </Grid>
          <hr width="85%" style={{ marginTop: isDestop ? "60px" : "100px" }} />
          <p
            style={{
              marginTop: "40px",
              fontFamily: "Open Sans",
              fontWeight: 400,
              fontSize: "14px",
              color: "white",
              textAlign: "center",
            }}
          >
            © Copyright 2023, All Rights Reserved by AwareCan
          </p>
        </div>
      </div>
    </>
  );
}
